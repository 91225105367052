import React, { ReactElement, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const UserComp = (): ReactElement => {
  const { keycloak } = useKeycloak();

  const [name, setName] = useState<undefined | string>(undefined);

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      // @ts-ignore
      setName(userInfo.name);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="topMenuUser">
      <div className="topMenuUserBlock">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C13.5913 0 15.1174 0.632141 16.2426 1.75736C17.3679 2.88258 18 4.4087 18 6C18 7.5913 17.3679 9.11742 16.2426 10.2426C15.1174 11.3679 13.5913 12 12 12C10.4087 12 8.88258 11.3679 7.75736 10.2426C6.63214 9.11742 6 7.5913 6 6C6 4.4087 6.63214 2.88258 7.75736 1.75736C8.88258 0.632141 10.4087 0 12 0ZM12 15C18.63 15 24 17.685 24 21V24H0V21C0 17.685 5.37 15 12 15Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </svg>

        <div>
          <span>{name || ''}</span>
          <br />
          <span>Holcim Polska</span>
        </div>
      </div>
    </div>
  );
};

export default UserComp;
