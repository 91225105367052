/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { CustomInfoBlock, RenderSvg } from 'components';
import React, { ReactElement } from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  comment?: string;
  title: string;
}

const TableCellComment = (props: Props): ReactElement => {
  const { id, comment, title } = props;

  return (
    <>
      {id && comment !== undefined && comment !== '' ? (
        <div id={`tableCellComment-${id}`} className="tableCellComment">
          <CustomInfoBlock
            customText={comment}
            customTitle={title}
            customSvg="comment"
            widthBox="high"
            maxLevel="lvl3"
          >
            <div id={`${id}TableCellComment`} className="circleBox">
              <RenderSvg nameSvg="iconInfo" />
            </div>
          </CustomInfoBlock>
        </div>
      ) : (
        '---'
      )}
    </>
  );
};

export default TableCellComment;
