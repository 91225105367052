import React, { ReactElement, useState } from 'react';
import './customInfoBlock.scss';
import { Box, Modal } from 'components';
import ShowTextarea from 'components/CustomShow/ShowTextarea';

interface Props {
  children: ReactElement;
  customSvg?: string;
  customTitle?: string;
  customText?: string;
  widthBox?: 'low400' | 'low' | 'medium' | 'high';
  maxLevel?: 'lvl2' | 'lvl3' | 'lvl4' | 'lvl5';
}

const CustomInfoBlock = (props: Props): ReactElement => {
  const {
    children,
    customSvg,
    customTitle,
    customText,
    widthBox,
    maxLevel,
  } = props;

  const [showBlock, setShowBlock] = useState<boolean>(false);
  return (
    <>
      <div
        role="button"
        onClick={() => {
          setShowBlock(true);
        }}
        onKeyUp={() => {}}
        tabIndex={0}
        className="CustomInfoBlockBox"
      >
        {children}
      </div>
      {showBlock === true ? (
        <Modal
          open
          changeModalShow={setShowBlock}
          widthBox={widthBox}
          maxLevel={maxLevel}
        >
          <Box
            id="customInfoBlock"
            name={customTitle || 'Pomoc'}
            svgIcon={customSvg || 'questionCircle'}
            noMarginBottom
            noShadow
          >
            <>
              <ShowTextarea
                id="textCustomInfoBlock"
                data={customText}
                normalSizeFont
              />
            </>
          </Box>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default CustomInfoBlock;
