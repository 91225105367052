import React, { ReactElement } from 'react';
import './Loader.scss';
import LoadingOverlay from 'react-loading-overlay';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  children?: ReactElement;
  active?: boolean;
  text?: string;
  className?: string;
}

const Loader = (props: Props): ReactElement => {
  const { children, active, text, className } = props;

  return (
    <LoadingOverlay
      classNamePrefix="loader"
      active={active}
      className={className}
      spinner
      text={text}
    >
      {children}
    </LoadingOverlay>
  );
};

export default Loader;
