/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState, useEffect } from 'react';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';
import SearchBox from '../ComponentFilter/SearchBox';
import SearchSelect from '../ComponentFilter/SearchSelect';
import '../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  customFilter: any;
  column: any;
  placeholder?: string;
  alignBox?: 'left' | 'center' | 'right';
  options?: any;
  valueDefault?: string;
}

const CustomFilterSelect = (props: Props): ReactElement => {
  const { t } = useTranslation('filterTable');

  const myRef = React.createRef();

  const {
    customFilter,
    column,
    placeholder,
    alignBox,
    options,
    valueDefault,
  } = props;

  const [openSearch, setOpenSearch] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchValue, setsearchValue] = useState<any>(valueDefault || '');

  useEffect(() => {
    if (searchValue === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }
  }, []);

  const handleSearch = () => {
    if (searchValue === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }
    customFilter({ [column.dataField]: searchValue });
    setOpenSearch(false);
  };

  // Reset filter
  const [resetFilter, setResetFilter] = useState(0);
  useEffect(() => {
    if (resetFilter !== 0) {
      handleSearch();
    }
  }, [resetFilter]);

  const handleSearchClear = () => {
    setsearchValue('');
    setResetFilter(Math.random);
  };
  // End reset filter

  const keyAction = (e: any) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <SearchBox
      handleSearch={handleSearch}
      labelSearch={t('filterAddress.search')}
      openSearch={openSearch}
      activeSearch={activeSearch}
      alignBox={alignBox}
      handleSearchClear={handleSearchClear}
    >
      <>
        <SearchSelect
          options={options}
          name={column.dataField}
          nameLabel={column.text}
          placeholder={placeholder}
          setValue={setsearchValue}
          keyAction={keyAction}
          setOpenSearch={setOpenSearch}
          value={searchValue}
          valueByKey={valueDefault}
        />
      </>
    </SearchBox>
  );
};

export default CustomFilterSelect;
