/* eslint-disable react/jsx-no-bind */
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ContentBox,
  CustomFilterAddress,
  CustomFilterContact,
  CustomFilterData,
  CustomFilterSelectTrainingCompetence,
  CustomFilterSelectTrainingType,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  Modal,
  ShowTitleTable,
  TableCellAddress,
  TableCellContact,
  TableCellImport,
  TableCellListToCell,
  TableCellTypeTrainings,
  TableCellYesNo,
  TablePagination,
  TableRecordAction,
} from 'components';
import { ManagerUserPlansTrainingsRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import { AddTrainingsMotivation } from 'api/types';
import LimitBlock from '../LimitBlock';
import ModalTrainingsShow from '../../../../Settings/TrainingsAndCompetence/Trainings/ModalTrainingsShow';
import MotivationModal from '../MotivationModal';

interface Props {
  changeModalShow: Dispatch<SetStateAction<boolean>>;
  maxLimits?: any;
  limitsData?: any;
  setUpdateLimits: Dispatch<SetStateAction<number>>;
  setUpdateDataTableParent: Dispatch<SetStateAction<number>>;
  id: string;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const {
    changeModalShow,
    maxLimits,
    limitsData,
    setUpdateLimits,
    setUpdateDataTableParent,
    id,
  } = props;
  const { t } = useTranslation('plansTrainings');

  const [motivationShow, setMotivationShow] = useState<boolean>(false);

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    name: 'name',
    type: 'type',
    competences: 'competences',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    name: 'TRAINING_NAME',
    type: 'TYPE',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = ManagerUserPlansTrainingsRepository.useGetAllUnselectedTrainingsActivePlansAdmin(
    id
  );
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const postDataSaveAndExit = ManagerUserPlansTrainingsRepository.useAddTrainingsToSelectedAdmin(
    id,
    async () => {
      setSubmitForm(false);
      setUpdateDataTable(Math.random);
      setMotivationShow(false);
      setUpdateLimits(Math.random);
      setUpdateDataTableParent(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = (dataPost: AddTrainingsMotivation) => {
    setSubmitForm(true);
    const dataModel = dataPost;
    if (dataModel.trainingId === undefined) {
      dataModel.trainingId = modalId;
    }
    postDataSaveAndExit(dataModel);
  };

  const handleImportClick = (idClick: number, requiredSlt: boolean) => {
    setModalId(idClick);

    if (requiredSlt) {
      setMotivationShow(true);
    } else {
      handleSaveAndExit({ sltMotivation: null, trainingId: idClick });
    }
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      left: false,
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={handleShowClick}
            importClick={() => {
              handleImportClick(row.id, row?.sltAcceptanceRequired);
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('userPlans.nameTrainings'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'type',
      text: t('userPlans.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerStyle: () => {
        return { width: '140px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.type} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingType
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
    },
    {
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      dataField: 'competences',
      text: t('userPlans.competences'),
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell data={row?.competences} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingCompetence
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
    },
    {
      dataField: 'sltAcceptanceRequired',
      text: t('userPlans.sltAcceptanceRequired'),
      headerStyle: () => {
        return { width: '120px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellYesNo data={row?.sltAcceptanceRequired} justify="center" />
        );
      },
    },
    {
      dataField: '',
      text: t('userPlans.select'),
      headerStyle: () => {
        return { width: '70px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="right"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellImport
            id={row?.id}
            handleImport={() => {
              handleImportClick(row?.id, row?.sltAcceptanceRequired);
            }}
            justify="toRight"
          />
        );
      },
    },
  ];

  return (
    <Modal open changeModalShow={changeModalShow}>
      <Box
        id="modalSelectTrainings"
        name={t('userPlans.listAvailableTrainings')}
        svgIcon="selectRecordBox"
        noShadow
        noPadding
        noMarginBottom
      >
        <>
          <LimitBlock maxLimits={maxLimits} limitsData={limitsData} />
          <ContentBox id="userPlansTrainingsTableSelectTrainings" noPadding>
            <>
              <LoaderTable active={loaderTable} noRadiusTop>
                <>
                  <TablePagination
                    rowInPage={rowsPerPage}
                    page={page}
                    changeRowInPage={setRowsPerPage}
                    data={data}
                    changePage={setPage}
                  >
                    <ShowTitleTable
                      id="userPlansTrainingsTableSelectTrainingsLabel"
                      label="Lista dostępnych szkoleń:"
                    />
                  </TablePagination>
                  <BootstrapTable
                    bootstrap4
                    hover
                    bordered={false}
                    keyField="id"
                    data={data?.content || []}
                    columns={columns}
                    noDataIndication={t('userPlans.noDataTableUnselected')}
                    remote={{
                      filter: true,
                      sort: true,
                    }}
                    onTableChange={onTableChange}
                    filter={filterFactory()}
                    defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                  />
                  <TablePagination
                    rowInPage={rowsPerPage}
                    page={page}
                    changeRowInPage={setRowsPerPage}
                    data={data}
                    changePage={setPage}
                  />
                </>
              </LoaderTable>
            </>
          </ContentBox>
          {modalShow ? (
            <ModalTrainingsShow changeModalShow={setModalShow} id={modalId} />
          ) : (
            ''
          )}
          {motivationShow ? (
            <MotivationModal
              changeShowModal={setMotivationShow}
              onSaveAndExit={handleSaveAndExit}
            />
          ) : (
            ''
          )}
        </>
      </Box>
    </Modal>
  );
};

export default TableTrainingsBlock;
