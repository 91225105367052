import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CustomContainer, LoaderTable, Tabs } from 'components';
import { IDPRepository } from 'api/repository';

import IDPEmployeeDetail from './IDPEmployeeDetail';

import IDPEmployeeArea from './IDPEmployeeArea';

import IDPEmployeeLeaderProfile from './IDPEmployeeLeaderProfile';

import './IDPDetail.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { id, action } = useParams<{
    id: string;
    idRecord: string;
    action: string;
  }>();

  const [IDPEmployee, fetchData] = IDPRepository.useGetIDPEmployeeUser({});
  const [idp] = IDPRepository.useGetIDPSettings({});

  const nameBox = () => {
    let textName = 'EDYCJA IDP - ';
    if (IDPEmployee && IDPEmployee.interviewType === 'ANNUAL') {
      textName = 'EDYCJA IDP - WPROWADZANIE DANYCH';
    }
    if (IDPEmployee && IDPEmployee.interviewType === 'FIRST_ANNUAL') {
      textName = 'EDYCJA IDP - WPROWADZANIE DANYCH';
    }
    if (IDPEmployee && IDPEmployee.interviewType === 'SIX_MONTH') {
      textName = 'EDYCJA IDP - ROZMOWA PÓŁROCZNA';
    }
    if (IDPEmployee && IDPEmployee.interviewType === 'LAST_ANNUAL') {
      textName = 'EDYCJA IDP - ROZMOWA ROCZNA';
    }
    return textName;
  };

  return (
    <>
      {idp !== null &&
      idp?.interviewType !== 'NONE' &&
      idp?.interviewType !== undefined ? (
        <CustomContainer>
          <LoaderTable active={IDPEmployee === undefined}>
            <Box
              id="IDPEmployeeBox"
              name={IDPEmployee === null ? 'DODAWANIE IDP' : nameBox()}
              svgIcon="addEdit"
              noPadding
            >
              <>
                {IDPEmployee === null ? (
                  <div id="IDPEmployeeContentBoxEmpty">
                    <IDPEmployeeDetail
                      IDPEmployee={IDPEmployee}
                      fetchData={fetchData}
                    />
                  </div>
                ) : (
                  <>
                    <Tabs
                      id="IDPEmployeeTabs"
                      items={[
                        {
                          id: 'dane-podstawowe',
                          label: 'Cele, komentarze, mobilność',
                          link: `/panel/IDP/dane-podstawowe`,
                          svgName: 'target',
                        },
                        {
                          id: 'obszary-rozwoju',
                          label: 'Obszary rozwoju',
                          link: `/panel/IDP/obszary-rozwoju`,
                          svgName: 'searchAndGraph',
                        },
                        /* {
                          id: 'profil-lidera',
                          label: 'Profil lidera',
                          link: `/panel/IDP/profil-lidera`,
                          svgName: 'employeeWithStar',
                        }, */
                      ]}
                    />
                    <div id="IDPEmployeeContentBox">
                      {action === 'dane-podstawowe' ? (
                        <IDPEmployeeDetail IDPEmployee={IDPEmployee} />
                      ) : (
                        ''
                      )}
                      {action === 'obszary-rozwoju' ? (
                        <IDPEmployeeArea
                          IDPEmployee={IDPEmployee}
                          fetchData={fetchData}
                        />
                      ) : (
                        ''
                      )}
                      {/* action === 'profil-lidera' ? (
                        <IDPEmployeeLeaderProfile
                          IDPEmployee={IDPEmployee}
                          fetchData={fetchData}
                        />
                      ) : (
                        ''
                      ) */}
                    </div>
                  </>
                )}
              </>
            </Box>
          </LoaderTable>
        </CustomContainer>
      ) : (
        <CustomContainer allowGrow>
          <Box
            id="IDPEmployeeBoxNoActivePlans"
            name="FORMULARZ IDP"
            svgIcon="addEdit"
            noContentStyle
          >
            <div id="IDPEmployeeNoActivePlans">
              <div className="noActivePlan">
                <div>
                  Dodawać i edytować IDP można tylko podczas rozmów półrocznych
                  i rocznych
                </div>
                <div>Powiadomimy Cię drogą mailową o ich rozpocząciu</div>
              </div>
            </div>
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default EditAcceptedTrainings;
