/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { BaseResponse, MultiBaseResponse } from 'api/types';
import { useMemo, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';

const serverOrigin = process.env.REACT_APP_SERVER_ORIGIN;

const gatewayPort = process.env.REACT_APP_API_GATEWAY_PORT;

const gatewayBasePath = process.env.REACT_APP_API_BASE_PATH;

export const apiUrl = `${serverOrigin}${
  process.env.NODE_ENV.includes('development') ||
  process.env.REACT_APP_STAGE === 'dev'
    ? ':'
    : ''
}${
  process.env.NODE_ENV.includes('development') ||
  process.env.REACT_APP_STAGE === 'dev'
    ? gatewayPort
    : ''
}${gatewayBasePath}`;

export const useAxios = (): AxiosInstance => {
  const { keycloak } = useKeycloak();
  const [token, setToken] = useState<string | undefined>(undefined);
  const instance = useMemo(() => {
    if (keycloak && keycloak.authenticated) {
      setToken(keycloak.token);
    }
    return axios.create({
      baseURL: apiUrl,
      responseType: 'json',
      headers: { Authorization: `Bearer ${token}` },
    });
  }, [keycloak, token]);

  instance.interceptors.response.use(
    async (response) => {
      if (response.data?.result) {
        const res = response as AxiosResponse<BaseResponse<any>>;
        res.data = res.data.result;
        return res;
      }
      if (response.data && response.data.results && !response.data.totalPages) {
        const res = response as AxiosResponse<MultiBaseResponse<any>>;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        res.data = res.data.results;
        return res;
      }
      return response;
    },
    (error) => {
      const err = error as AxiosError<any>;

      if (err.response) {
        err.response.data = err.response.request.response.message;
      }
      return Promise.reject(err);
    }
  );

  return instance;
};

export const useAxiosPdf = (): AxiosInstance => {
  const { keycloak } = useKeycloak();
  const instance = useMemo(() => {
    return axios.create({
      baseURL: apiUrl,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${keycloak.token}` },
    });
  }, [keycloak, keycloak.token]);

  instance.interceptors.response.use(
    async (response) => {
      if (response.data?.result) {
        const res = response as AxiosResponse<BaseResponse<any>>;
        res.data = res.data.result;
        return res;
      }
      if (response.data && response.data.results && !response.data.totalPages) {
        const res = response as AxiosResponse<MultiBaseResponse<any>>;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        res.data = res.data.results;
        return res;
      }
      return response;
    },
    (error) => {
      const err = error as AxiosError<any>;

      if (err.response) {
        err.response.data = err.response.request.response.message;
      }
      return Promise.reject(err);
    }
  );

  return instance;
};
