/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  Box,
  ContentPlansTrainings,
  ActionBeforeCatalog,
  LoaderTable,
  CustomContainer,
  CustomMultiSelect,
  LoaderForm,
  RenderSvg,
  ExitFromForm,
  CustomSubmit,
  CustomSelect,
} from 'components';
import { PlansTrainingsRepository } from 'api/repository';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './formGenerateReportsIDP.scss';
import { useForm } from 'react-hook-form';
import { triggerValidRequired } from 'utils';
import { ExportGenerateReportsIDPPost } from 'api/types/IDP';

type ValueModel = ExportGenerateReportsIDPPost;

interface Props {
  dataId: number;
  generatePDF?: boolean;
  cancel?: () => void;
  submitForm: boolean;
  onGenerate?: (data: ValueModel) => void;
  link?: string;
  data: any;
}

const formGenerateReportsIDP = (props: Props): ReactElement => {
  const { t } = useTranslation('plansTrainings');
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const { dataId, submitForm, cancel, onGenerate, link, data } = props;

  const [actionGenerate, setActionGenerate] = useState<boolean>(false);

  const [updateFileName, setUpadteFileName] = useState<number>(0);

  //  const formSchema: yup.SchemaOf<ValueModel> = yup.object().shape({});

  const [nameDownloadFile, setNameDownloadFile] = useState<string>('');

  const formSchema: yup.SchemaOf<ValueModel> = yup.object().shape({
    type: yup.string().required(t('generateReports.typeMessage')),
  });

  useEffect(() => {
    if (link !== undefined) {
      setActionGenerate(false);
    }
  }, [link]);

  const { handleSubmit, errors, setValue, trigger } = useForm<ValueModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleGenerate = (dataSubmit: ValueModel) => {
    setChangeForm(false);
    setActionGenerate(true);
    if (onGenerate) {
      onGenerate(dataSubmit);

      if (dataSubmit.type === 'excelEmployeeIdp') {
        setNameDownloadFile(`Raport wypełnienia IDP.xlsx`);
      } else if (dataSubmit.type === 'excelSltEmployeeIdp') {
        setNameDownloadFile(`Raport wypełnienia IDP dla zarządu.xlsx`);
      } else {
        setNameDownloadFile(`${t('generateReports.report')}.xlsx`);
      }
    }
  };

  // START Option

  const generateReportsOption = () => {
    const generateReportsOptions = [] as { [key: string]: string }[];

    generateReportsOptions.push({
      value: 'excelEmployeeIdp',
      label: 'Raport wypełnienia IDP - excel',
    });

    generateReportsOptions.push({
      value: 'excelSltEmployeeIdp',
      label: 'Raport wypełnienia IDP dla zarządu - excel',
    });
    return generateReportsOptions;
  };

  // END OPTION
  return (
    <>
      <ExitFromForm changeForm={changeForm} />
      <Form
        className="formGenerateReportsIDP"
        onChange={() => {
          setChangeForm(true);
        }}
      >
        <LoaderForm active={submitForm} type="saving">
          <>
            <Row className="justify-content-md-center">
              <Col>
                <CustomSelect
                  name="type"
                  label={t('generateReports.generateReports')}
                  setValue={setValue}
                  fullWidth
                  required
                  error={errors?.type?.message}
                  options={generateReportsOption()}
                  placeholder={t('generateReports.generateReportsPlaceholder')}
                  validRequiredSelect={() => {
                    triggerValidRequired('type', trigger);
                  }}
                  valueByKey="excelEmployeeIdp"
                  // infoStringHtml={t(
                  //   'formCompanyChemistryPrint.categoryPlaceholderInfo'
                  // )}
                />
              </Col>
            </Row>
          </>
        </LoaderForm>

        <>
          <CustomSubmit
            saveAndGeneratePdf={handleSubmit(handleGenerate)}
            cancel={cancel}
            submitForm={submitForm}
            link={link}
            nameDownloadFile={nameDownloadFile}
            actionGenerate={actionGenerate}
          />
        </>
      </Form>
    </>
  );
};

export default formGenerateReportsIDP;
