import React, { ReactElement } from 'react';
import { useStoreActions, useStoreState } from 'store/userSettings';
import { Link } from 'react-router-dom';
import LogoIcon from './LogoIcon';

// import useStyles from './useStyles';

const ConfigMenuComp = (): ReactElement => {
  // const classes = useStyles();

  const isPin = useStoreState((state) => state.userSettings.isPin);

  const changePin = useStoreActions(
    (actions) => actions.userSettings.changePin
  );

  const handlePin = () => {
    if (isPin === true) {
      changePin(false);
    } else {
      changePin(true);
    }
  };

  // function CheckPin() {
  const checkPin = () => {
    if (isPin) {
      return (
        <svg
          className="pinActive"
          width="12"
          height="17"
          viewBox="0 0 12 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11.0606 12.1689C11.0859 10.5387 10.1748 9.08603 8.76474 8.19688L8.85245 2.53356L9.24526 2.53965C9.5578 2.54449 9.8595 2.42272 10.084 2.20113C10.3085 1.97953 10.4373 1.67627 10.4423 1.35804C10.4472 1.03982 10.3278 0.732712 10.1103 0.504272C9.89275 0.275833 9.59497 0.144777 9.28243 0.139936L2.21185 0.0304209C1.89931 0.02558 1.59762 0.147351 1.37313 0.368944C1.14865 0.590538 1.01977 0.893803 1.01484 1.21202C1.00991 1.53025 1.12934 1.83736 1.34685 2.0658C1.56436 2.29424 1.86214 2.42529 2.17468 2.43013L2.56749 2.43622L2.47978 8.09954C1.0428 8.94459 0.0872086 10.3684 0.0619586 11.9986L4.77568 12.0716L4.72612 15.2712C4.71931 15.7111 5.06726 16.0766 5.49935 16.0833C5.93144 16.09 6.29055 15.7355 6.29736 15.2955L6.34692 12.0959L11.0606 12.1689Z" />
        </svg>
      );
    }
    return (
      <svg
        className="pinUnactive"
        width="12"
        height="17"
        viewBox="0 0 12 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.5347 11.6607L6.35466 11.596L5.85472 11.5882L5.84698 12.0882L5.79742 15.2878C5.79475 15.4601 5.65462 15.5856 5.50709 15.5833C5.35957 15.581 5.22339 15.4513 5.22606 15.2789L5.27562 12.0793L5.28336 11.5794L4.78342 11.5716L0.603335 11.5069C0.788527 10.2837 1.57616 9.21098 2.73323 8.53054L2.97537 8.38814L2.97972 8.10728L3.06743 2.44396L3.07518 1.94402L2.57524 1.93628L2.18243 1.93019C2.00542 1.92745 1.8349 1.85328 1.70896 1.72101C1.58272 1.58843 1.51186 1.40828 1.51478 1.21977C1.5177 1.03126 1.5941 0.85339 1.72439 0.724781C1.85437 0.596472 2.0271 0.527619 2.20411 0.530361L9.27469 0.639876C9.4517 0.642618 9.62221 0.716787 9.74816 0.849059L10.1103 0.504272L9.74816 0.84906C9.8744 0.981643 9.94526 1.1618 9.94234 1.3503C9.93942 1.53881 9.86301 1.71668 9.73273 1.84529L10.084 2.20113L9.73273 1.84529C9.60275 1.9736 9.43002 2.04245 9.25301 2.03971L8.8602 2.03362L8.36026 2.02588L8.35251 2.52582L8.2648 8.18914L8.26045 8.47L8.49805 8.61983C9.6335 9.33578 10.3875 10.4323 10.5347 11.6607Z" />
      </svg>
    );
  };

  return (
    <div className="menuTop">
      <>
        <LogoIcon />
        <div className="logoBox">
          <Link to="/panel/tablica" className="logo">
            <img style={{ width: '100%' }} src="/pm.png" alt="" />
          </Link>
          <div
            className="pinBox"
            onClick={handlePin}
            onKeyDown={handlePin}
            role="button"
            tabIndex={0}
          >
            {checkPin()}
          </div>
        </div>
      </>
    </div>
  );
};

export default ConfigMenuComp;
