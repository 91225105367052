import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  ContentPlansTrainings,
  ActionBeforeCatalog,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
} from 'components';
import { ManagerUserPlansTrainingsRepository } from 'api/repository';
import './employee.scss';
import TableEmployeeBlock from './TableEmployeeBlock';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const { id } = useParams<{ id: string }>();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [
    data,
  ] = ManagerUserPlansTrainingsRepository.useGetActivePlansTrainings();

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={loaderTable} radiusTop allowGrow>
          <>
            {data !== null && data !== undefined && data !== false ? (
              <>
                <CustomContainer>
                  <LoaderTable radiusTop allowGrow>
                    <>
                      <Box
                        id="userPlansTrainingsEnrollmentBox"
                        name={t('userPlans.enrollmentForTraining')}
                        svgIcon="enrollmentForTrainings"
                        noPadding
                      >
                        <>
                          <ContentBox
                            id="userPlansTrainingsEnrollmentBasicInformation"
                            bottomBorder
                          >
                            <>
                              <div className="planNameBox">
                                <div className="titleName">
                                  Wybrany plan szkoleń:
                                </div>
                                <div className="name">{data?.name}</div>
                              </div>
                              <div className="dateBox">
                                <div id="frameDate" className="dateContent">
                                  <div id="frameDateTitle" className="title">
                                    {t('showPlans.frameDate')}
                                  </div>
                                  <div id="frameDateDetail" className="content">
                                    <div id="frameDatesIcon" className="icon">
                                      <RenderSvg nameSvg="frameDate" />
                                    </div>
                                    <div
                                      id="frameDateBoxDate"
                                      className="dateDetail"
                                    >
                                      <div id="frameDateStart">
                                        {t('showPlans.from')}
                                        <span className="start">
                                          {data?.startDate}
                                        </span>
                                      </div>
                                      <div id="frameDateEnd">
                                        {t('showPlans.to')}
                                        <span className="end">
                                          {data?.endDate}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="frameRegistrationDate"
                                  className="dateContent"
                                >
                                  <div
                                    id="frameRegistrationDateTitle"
                                    className="title"
                                  >
                                    {t('showPlans.frameRegisterDate')}
                                  </div>
                                  <div
                                    id="frameRegistrationDateDetail"
                                    className="content"
                                  >
                                    <div
                                      id="frameRegistrationDateIcon"
                                      className="icon"
                                    >
                                      <RenderSvg nameSvg="frameRegistrationDate" />
                                    </div>
                                    <div
                                      id="frameRegistrationDateBoxDate"
                                      className="dateDetail"
                                    >
                                      <div id="frameRegistrationDateStart">
                                        {t('showPlans.from')}
                                        <span className="start">
                                          {data?.registrationStartDate}
                                        </span>
                                      </div>
                                      <div id="frameRegistrationDateEnd">
                                        {t('showPlans.to')}
                                        <span className="end">
                                          {data?.registrationEndDate}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="validationDate"
                                  className="dateContent"
                                >
                                  <div
                                    id="validationDateTitle"
                                    className="title"
                                  >
                                    {t('showPlans.validDate')}
                                  </div>
                                  <div
                                    id="validationDateDetail"
                                    className="content"
                                  >
                                    <div
                                      id="validationDateIcon"
                                      className="icon"
                                    >
                                      <RenderSvg nameSvg="dateValidationDate" />
                                    </div>
                                    <div
                                      id="validationDateBoxDate"
                                      className="dateDetail"
                                    >
                                      <div id="validationDateEnd">
                                        <span className="end">
                                          {data?.verificationDate}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </ContentBox>
                          <TableEmployeeBlock />
                        </>
                      </Box>
                    </>
                  </LoaderTable>
                </CustomContainer>
              </>
            ) : (
              <Box
                id="userPlansTrainingsBox"
                name={t('userPlans.enrollmentForTraining')}
                svgIcon="enrollmentForTrainings"
                noContentStyle
              >
                <div className="userPlansTrainingsNoActivePlans">
                  <div className="noActivePlan">
                    <div>{t('userPlans.noActivePlansPart1')}</div>
                    <div>{t('userPlans.noActivePlansPart2')}</div>
                  </div>
                </div>
              </Box>
            )}
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default Trainigns;
