import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  Box,
  ContentPlansTrainings,
  ActionBeforeCatalog,
  LoaderTable,
  CustomContainer,
  Modal,
} from 'components';
import { PlansTrainingsRepository } from 'api/repository';
import PlansTrainings from 'routes/Settings/PlansTrainings';
// import CatalogsPlans from './CatalogsPlans';
// import PlansTrainingsShow from './PlansTrainingsShow';

interface Props {
  changeModalTrainings: Dispatch<SetStateAction<boolean>>;
}

const modalTrainigns = (props: Props): ReactElement => {
  const { changeModalTrainings } = props;

  return (
    <>
      <Modal
        open
        changeModalShow={changeModalTrainings}
        widthBox="high"
        maxLevel="lvl2"
      >
        <PlansTrainings generatePDF />
      </Modal>
    </>
  );
};

export default modalTrainigns;
