/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import CustomTooltip from 'components/CustomTooltip';
import React, { ReactElement } from 'react';
import './tableCellLastFirstNameRequiredHG.scss';
/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  last?: string;
  first?: string;
  tooltipRequiredHG?: boolean;
  goalsCount: number;
  idp?: boolean;
}

const TableCellLastFirstName = (props: Props): ReactElement => {
  const { id, last, first, tooltipRequiredHG, goalsCount, idp } = props;

  return (
    <>
      {id ? (
        <div
          id={`tableCellBlockLastFirstNameRequiredHG-${id}`}
          className="tableCellBlockLastFirstNameRequiredHG"
        >
          {last || ''} {first || ''}
          {tooltipRequiredHG ? (
            <CustomTooltip
              customTitle="WYMAGANIA"
              /* customHtml={
                idp === true
                  ? goalsCount !== undefined &&
                    goalsCount !== null &&
                    goalsCount > 0
                    ? 'Liczba obszarów została uzupełniona'
                    : 'Liczba obszarów nie została uzupełniona'
                  : 
              } */
              customHtml={
                idp === true
                  ? 'Pracownik musi mieć przypisane obszary'
                  : 'Pracownik musi mieć przypisane cele'
              }
              /*  customHtml={
                idp === true
                  ? goalsCount !== undefined &&
                    goalsCount !== null &&
                    goalsCount > 0
                    ? ''
                    : 'Pracownik musi mieć przypisane obszary'
                  : goalsCount !== undefined &&
                    goalsCount !== null &&
                    goalsCount > 0
                  ? ''
                  : 'Pracownik musi mieć przypisane cele'
              } */
              /* infoColor={
                goalsCount !== undefined &&
                goalsCount !== null &&
                goalsCount > 0
                  ? 'green'
                  : 'red'
              } */
              infoColor="green"
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellLastFirstName;
