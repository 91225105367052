/* eslint-disable react-hooks/rules-of-hooks */
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from 'components';
import { IDPRepository } from 'api/repository';
import {
  ExportGenerateReportsIDPAdditionalParamGetPutDelete,
  ExportGenerateReportsIDPPost,
} from 'api/types/IDP';
import FormGenerateReportsIDP from '../FormGenerateReportsIDP';

// import CatalogsPlans from './CatalogsPlans';
// import PlansTrainingsShow from './PlansTrainingsShow';

interface Props {
  dataId: number;
  changeModalGenerateReports: Dispatch<SetStateAction<boolean>>;
  // onChange: () => void;
  data: any;
}

const modalGenerateReportsIDP = (props: Props): ReactElement => {
  const { dataId, data, changeModalGenerateReports } = props;
  const { t } = useTranslation('plansTrainings');

  const [pdfBlob, setPdfBlob] = useState<any | undefined>(undefined);
  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const generateReports = IDPRepository.usePostGenerateReportIDPExport(
    async (blobExcel) => {
      const fileGenerateReports = new Blob([blobExcel], {
        type: 'application/excel',
      });
      const fileURL = URL.createObjectURL(fileGenerateReports);
      setPdfBlob(fileURL);
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleGenerate = (dataForm: ExportGenerateReportsIDPPost) => {
    setSubmitForm(true);
    const additionalParam: ExportGenerateReportsIDPAdditionalParamGetPutDelete = {
      idpId: dataId,
    };
    generateReports(dataForm, additionalParam);
  };

  return (
    <>
      <Modal
        open
        changeModalShow={changeModalGenerateReports}
        widthBox="high"
        maxLevel="lvl3"
      >
        <Box
          id="plansIDPBox"
          name={t('generateReports.nameBoxGenerateReportsIDP')}
          svgIcon="addEdit"
          noContentStyle
        >
          <FormGenerateReportsIDP
            dataId={dataId}
            /* cancel={() => {
              changeModalEditPrint(false);
            }} */
            submitForm={submitForm}
            link={pdfBlob}
            onGenerate={handleGenerate}
            data={data}
          />
        </Box>
      </Modal>
    </>
  );
};

export default modalGenerateReportsIDP;
