/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './catalogsPlansEmployeeAppraisal.scss';
import CatalogsPlansItemIDP from './CatalogsPlansItemEmployeeAppraisal/CatalogsPlansItemEmployeeAppraisal';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  data?: any;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data } = props;

  return (
    <div className="CatalogsPlansItems" id={id}>
      {data
        ? data.map((item: any) => {
            return <CatalogsPlansItemIDP id={id} data={item} />;
          })
        : ''}
    </div>
  );
};

export default ContentBox;
