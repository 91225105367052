import React, { ReactElement, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute';
import NotFound from './NotFound';
import Test from './Test';
import Trainings from './Settings/TrainingsAndCompetence';
import TrainingsProvider from './Settings/TrainingsProvider';
import TrainingsProviderAdd from './Settings/TrainingsProvider/TrainingsProviderAddPage';
import TrainingsProviderEdit from './Settings/TrainingsProvider/TrainingsProviderEditPage';
import CatalogTrainings from './Settings/CatalogsTrainings';
import PlansTrainings from './Settings/PlansTrainings';
import PlansTrainingsAdd from './Settings/PlansTrainings/PlansTrainingsAdd';
import PlansTrainingsEdit from './Settings/PlansTrainings/PlansTrainingsEdit';
import UserPlansTrainings from './User/PlansTrainings';
import ManagerPlansTrainingsEmpolyee from './Manager/PlansTrainings';
import ManagerPlansTrainingsEmpolyeeTrainings from './Manager/PlansTrainings/PlansTrainings';
import AdministrationPanel from './Administration/Panel';
import AdministrationAcceptedEmployee from './Administration/AcceptedTrainings';
import AdministrationAcceptedEmployeeTrainings from './Administration/AcceptedTrainings/AcceptedTrainings';
import SettingsPanel from './Settings/Panel';
import AdministrationTrainingsGroup from './Administration/TrainingsGroup';
import AdministrationTrainingsGroupList from './Administration/TrainingsGroup/TrainingsGroupList';
import AdministrationTrainingsGroupDetail from './Administration/TrainingsGroup/TrainingsGroupDetail';
import AdministrationTrainingsGroupAdd from './Administration/TrainingsGroup/TrainingsGroupAdd';
import TrainingsCompanyContact from './Settings/CompanyContact';
import UserTrainingsRegisterGroup from './User/TrainingsRegisterGroup';
import UserTrainingsRegisterGroupSelectGroup from './User/TrainingsRegisterGroup/TrainingsRegisterGroupSelectGroup';
import AdministrationLoyalty from './Administration/Loyalty';
import AdministrationReports from './Administration/Reports';
import AdministrationLoyaltyEdit from './Administration/Loyalty/LoyaltyEdit';
import UserQuestionnaire from './User/Questionnaire';
import UserQuestionnaireEdit from './User/Questionnaire/QuestionnaireEdit';
import ManagerTrainingsGroupList from './Manager/TrainingsGroup';
import ManagerTrainingsGroupEmployee from './Manager/TrainingsGroup/Employee/TrainingsRegisterGroup';
import ManagerTrainingsGroupEmployeeSelectTraining from './Manager/TrainingsGroup/Employee/TrainingsRegisterGroup/TrainingsRegisterGroupSelectGroup';
import SettingsIDP from './Settings/IDP';
import SettingsIDPEdit from './Settings/IDP/IDPEdit';
import ManagerIDP from './Manager/IDP';
import ManagerIDPDetail from './Manager/IDP/IDPDetail';
import SettingsEmployeeAppraisal from './Settings/EmployeeAppraisal';
import SettingsEmployeeAppraisalEdit from './Settings/EmployeeAppraisal/EmployeeAppraisalEdit';
import ManagerEmployeeAppraisal from './Manager/EmployeeAppraisal';
import ManagerEmployeeAppraisalEdit from './Manager/EmployeeAppraisal/EmployeeAppraisalDetail';
import UserIDP from './User/IDP';
import UserAppraisal from './User/EmployeeAppraisal';
import ManagerGroupEmployeeAppraisal from './Manager/GroupEmployeeAppraisal';
import UserPanel from './User/Panel';
import ManagerPanel from './Manager/Panel';
import ManagerIDPFeedback from './Manager/IDP/EmployeeFeedback';
import AdministrationIDPEmployee from './Administration/IDP';
import AdministrationEmployeeAppraisal from './Administration/EmployeeAppraisal';
import AdministrationPlansTrainingsEmpolyee from './Administration/PlansTrainings';
import AdministrationPlansTrainingsEmpolyeeTrainings from './Administration/PlansTrainings/PlansTrainings';

const appRoutes = {
  home: '/',
  login: '/logowanie',
  user: '/panel',
  test: '/panel/test',
};

const appRoutersUser = {
  userPanel: '/panel/tablica',
  userTrainings: '/panel/szkolenia',
  userTrainingsPlan: '/panel/szkolenia/plan',
  userTrainingsRegisterGroup: '/panel/szkolenia/termin-szkolenia',
  userTrainingsRegisterGroupSelectGroup:
    '/panel/szkolenia/termin-szkolenia/:id',
  userQuestionnaire: '/panel/szkolenia/ankiety',
  userQuestionnaireEdit: '/panel/szkolenia/ankiety/:idGroup/:idQuestionnaire',
  userIDPDetail: '/panel/IDP/:action',
  userEmployeeAppraisalDetail: '/panel/ocena-pracownika/:action',
  userJustyna: '/panel/justyna',
};

const appRoutersAdministration = {
  administration: '/administracja',
  administrationPanel: '/administracja/tablica',
  administrationTrainings: '/administracja/szkolenia',
  administrationAcceptedTrainings:
    '/administracja/szkolenia/akceptacja-szkolen/pracownicy',
  administrationAcceptedTrainingsDetail:
    '/administracja/szkolenia/akceptacja-szkolen/pracownicy/:id',
  administrationTrainingsGroup: '/administracja/szkolenia/grupy-szkoleniowe',
  administrationTrainingsGroupList:
    '/administracja/szkolenia/grupy-szkoleniowe/:id',
  administrationTrainingsGroupAdd:
    '/administracja/szkolenia/grupy-szkoleniowe/:id/formularz',
  administrationTrainingsGroupDetail:
    '/administracja/szkolenia/grupy-szkoleniowe/:id/:idRecord/:action',
  administrationLoyalty: '/administracja/umowy-lojalnosciowe',
  administrationLoyaltyEdit: '/administracja/umowy-lojalnosciowe/:id',
  administrationReports: '/administracja/raporty',
  administrationIDPEmployee: '/administracja/IDP/pracownicy',
  administrationEmployeeAppraisal: '/administracja/ocena-pracownika/pracownicy',
  administrationPlansEmployee: '/administracja/szkolenia/plan',
  administrationPlansDetail: '/administracja/szkolenia/plan/:id',
};

const appRoutersManager = {
  manager: '/kierownik',
  managerPanel: '/kierownik/tablica',
  managerTrainings: '/kierownik/szkolenia',
  managerPlansEmployee: '/kierownik/szkolenia/plan',
  managerPlansDetail: '/kierownik/szkolenia/plan/:id',
  managerTrainingsGroupList: '/kierownik/szkolenia/grupy-szkoleniowe',
  managerTrainingsGroupEmployee: '/kierownik/szkolenia/grupy-szkoleniowe/:id',
  managerTrainingsGroupEmployeeSelectTraining:
    '/kierownik/szkolenia/grupy-szkoleniowe/:id/:idTraining',
  managerIDPEmployee: '/kierownik/IDP/pracownicy',
  managerIDPDetail: '/kierownik/IDP/pracownicy/:idEmployee/:action',
  managerIDPFeedback: '/kierownik/IDP/pracownik/feedback',
  managerEmployeeAppraisal: '/kierownik/ocena-pracownika/pracownik',
  managerEmployeeAppraisalDetail:
    '/kierownik/ocena-pracownika/pracownik/:idEmployee/:action',
  managerGroupEmployeeAppraisal: '/kierownik/ocena-pracownika/grupowa-ocena',
};

const appRoutersSettings = {
  settings: '/ustawienia',
  settingsPanel: '/ustawienia/panel',
  settingsTraining: '/ustawienia/szkolenia',
  settingsTrainingTrainingAndCompetencies:
    '/ustawienia/szkolenia/szkolenia-i-kompetencje',
  settingsTrainingTrainingProvider: '/ustawienia/szkolenia/dostawcy-szkolen',
  settingsTrainingTrainingProviderAdd:
    '/ustawienia/szkolenia/dostawcy-szkolen/formularz',
  settingsTrainingTrainingProviderEdit:
    '/ustawienia/szkolenia/dostawcy-szkolen/formularz/:id/:action',
  settingsTrainingTrainingCatalog: '/ustawienia/szkolenia/katalog-szkolen',
  settingsTrainingPlansTrainings: '/ustawienia/szkolenia/plan-szkolen',
  settingsTrainingPlansTrainingsAdd:
    '/ustawienia/szkolenia/plan-szkolen/formularz',
  settingsTrainingPlansTrainingsEdit:
    '/ustawienia/szkolenia/plan-szkolen/formularz/:id/:action',
  settingsTrainingCompanyContact: '/ustawienia/szkolenia/osoby-kontaktowe',
  settingsIDP: '/ustawienia/IDP/lista',
  settingsIDPEdit: '/ustawienia/IDP/formularz',
  settingsEmployeeAppraisal: '/ustawienia/ocena-pracownika/lista',
  settingsEmployeeAppraisalEdit: '/ustawienia/ocena-pracownika/formularz',
};

export const RoutedContent = (): ReactElement => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  return (
    <Switch>
      <Route exact path={appRoutersUser.userPanel} component={UserPanel} />

      <Route exact path={appRoutes.home}>
        <Redirect to={appRoutersUser.userPanel} />;
      </Route>

      <AuthRoute
        path={appRoutersSettings.settingsTrainingTrainingProviderEdit}
        Component={TrainingsProviderEdit}
        exact
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        path={appRoutersSettings.settingsTrainingTrainingProviderAdd}
        Component={TrainingsProviderAdd}
        exact
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        path={appRoutersSettings.settingsPanel}
        Component={SettingsPanel}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        path={appRoutersSettings.settingsTrainingTrainingAndCompetencies}
        Component={Trainings}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        path={appRoutersSettings.settingsTrainingTrainingProvider}
        Component={TrainingsProvider}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersSettings.settingsTrainingTrainingCatalog}
        Component={CatalogTrainings}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersSettings.settingsTrainingPlansTrainingsEdit}
        Component={PlansTrainingsEdit}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersSettings.settingsTrainingPlansTrainingsAdd}
        // @ts-ignore
        Component={PlansTrainingsAdd}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersSettings.settingsTrainingPlansTrainings}
        Component={PlansTrainings}
        requiredRoles={['training_department', 'admin_phr']}
      />

      <AuthRoute
        exact
        path={appRoutersSettings.settingsIDPEdit}
        Component={SettingsIDPEdit}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersSettings.settingsIDP}
        Component={SettingsIDP}
        requiredRoles={['training_department', 'admin_phr']}
      />

      <Route exact path="/ustawienia/IDP">
        <Redirect to={appRoutersSettings.settingsIDPEdit} />;
      </Route>

      <AuthRoute
        exact
        path={appRoutersSettings.settingsEmployeeAppraisalEdit}
        Component={SettingsEmployeeAppraisalEdit}
        requiredRoles={['training_department', 'admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersSettings.settingsEmployeeAppraisal}
        Component={SettingsEmployeeAppraisal}
        requiredRoles={['training_department', 'admin_phr']}
      />

      <Route exact path="/ustawienia/ocena-pracownika">
        <Redirect to={appRoutersSettings.settingsEmployeeAppraisalEdit} />;
      </Route>

      <AuthRoute
        exact
        path={appRoutersSettings.settingsTrainingCompanyContact}
        Component={TrainingsCompanyContact}
        requiredRoles={['training_department', 'admin_phr']}
      />

      <Route exact path={appRoutes.user}>
        <Redirect to={appRoutersUser.userPanel} />;
      </Route>
      <Route exact path={appRoutersSettings.settings}>
        <Redirect to={appRoutersSettings.settingsPanel} />;
      </Route>
      <Route exact path={appRoutersSettings.settingsTraining}>
        <Redirect
          to={appRoutersSettings.settingsTrainingTrainingAndCompetencies}
        />
        ;
      </Route>
      <AuthRoute
        exact
        path={appRoutersUser.userTrainingsRegisterGroup}
        Component={UserTrainingsRegisterGroup}
        requiredRoles={['user']}
      />
      <AuthRoute
        exact
        path={appRoutersUser.userTrainingsRegisterGroupSelectGroup}
        Component={UserTrainingsRegisterGroupSelectGroup}
        requiredRoles={['user']}
      />
      <AuthRoute
        exact
        path={appRoutersUser.userTrainingsPlan}
        Component={UserPlansTrainings}
        requiredRoles={['user']}
      />
      <AuthRoute
        exact
        path={appRoutersUser.userQuestionnaire}
        Component={UserQuestionnaire}
        requiredRoles={['user']}
      />

      <AuthRoute
        exact
        path={appRoutersUser.userQuestionnaireEdit}
        Component={UserQuestionnaireEdit}
        requiredRoles={['user']}
      />

      <AuthRoute
        exact
        path={appRoutersUser.userIDPDetail}
        Component={UserIDP}
        requiredRoles={['user']}
      />

      <AuthRoute
        exact
        path={appRoutersUser.userEmployeeAppraisalDetail}
        Component={UserAppraisal}
        requiredRoles={['user']}
      />

      <Route exact path="/panel/IDP">
        <Redirect to="/panel/IDP/dane-podstawowe" />;
      </Route>

      <Route exact path="/panel/ocena-pracownika">
        <Redirect to="/panel/ocena-pracownika/cele-roczne" />
      </Route>

      <Route exact path={appRoutersUser.userTrainings}>
        <Redirect to={appRoutersUser.userTrainingsPlan} />;
      </Route>

      <AuthRoute
        exact
        path={appRoutersManager.managerPlansEmployee}
        Component={ManagerPlansTrainingsEmpolyee}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerPlansDetail}
        Component={ManagerPlansTrainingsEmpolyeeTrainings}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerIDPEmployee}
        Component={ManagerIDP}
        requiredRoles={['manager', 'second-level-manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerIDPDetail}
        Component={ManagerIDPDetail}
        requiredRoles={['manager', 'second-level-manager']}
      />

      <Route exact path="/kierownik/IDP">
        <Redirect to={appRoutersManager.managerIDPEmployee} />;
      </Route>

      <AuthRoute
        exact
        path={appRoutersManager.managerEmployeeAppraisal}
        Component={ManagerEmployeeAppraisal}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerIDPFeedback}
        Component={ManagerIDPFeedback}
        requiredRoles={['manager']}
      />

      <Route exact path="/kierownik/ocena-pracownika">
        <Redirect to={appRoutersManager.managerEmployeeAppraisal} />;
      </Route>

      <AuthRoute
        exact
        path={appRoutersManager.managerEmployeeAppraisalDetail}
        Component={ManagerEmployeeAppraisalEdit}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerGroupEmployeeAppraisal}
        Component={ManagerGroupEmployeeAppraisal}
        requiredRoles={['second-level-manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerTrainingsGroupList}
        Component={ManagerTrainingsGroupList}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerTrainingsGroupEmployee}
        Component={ManagerTrainingsGroupEmployee}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerTrainingsGroupEmployeeSelectTraining}
        Component={ManagerTrainingsGroupEmployeeSelectTraining}
        requiredRoles={['manager']}
      />

      <AuthRoute
        exact
        path={appRoutersManager.managerPanel}
        Component={ManagerPanel}
        requiredRoles={['manager']}
      />

      <Route exact path={appRoutersManager.manager}>
        <Redirect to={appRoutersManager.managerPanel} />;
      </Route>
      <Route exact path={appRoutersManager.managerTrainings}>
        <Redirect to={appRoutersManager.managerPlansEmployee} />;
      </Route>

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationPanel}
        Component={AdministrationPanel}
        requiredRoles={[
          'training_department',
          'slt_department',
          'hr_bp',
          'admin_phr',
        ]}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationAcceptedTrainings}
        Component={AdministrationAcceptedEmployee}
        requiredRoles={['training_department', 'slt_department', 'hr_bp']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationAcceptedTrainingsDetail}
        Component={AdministrationAcceptedEmployeeTrainings}
        requiredRoles={['training_department', 'slt_department', 'hr_bp']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationTrainingsGroup}
        Component={AdministrationTrainingsGroup}
        requiredRoles={['training_department']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationTrainingsGroupList}
        Component={AdministrationTrainingsGroupList}
        requiredRoles={['training_department']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationTrainingsGroupDetail}
        Component={AdministrationTrainingsGroupDetail}
        requiredRoles={['training_department']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationTrainingsGroupAdd}
        Component={AdministrationTrainingsGroupAdd}
        requiredRoles={['training_department']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationLoyalty}
        Component={AdministrationLoyalty}
        requiredRoles={['training_department']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationLoyaltyEdit}
        Component={AdministrationLoyaltyEdit}
        requiredRoles={['training_department']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationIDPEmployee}
        Component={AdministrationIDPEmployee}
        requiredRoles={['admin_phr']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationEmployeeAppraisal}
        Component={AdministrationEmployeeAppraisal}
        requiredRoles={['admin_phr']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationPlansEmployee}
        Component={AdministrationPlansTrainingsEmpolyee}
        requiredRoles={['admin_phr']}
      />

      <AuthRoute
        exact
        path={appRoutersAdministration.administrationPlansDetail}
        Component={AdministrationPlansTrainingsEmpolyeeTrainings}
        requiredRoles={['admin_phr']}
      />
      <AuthRoute
        exact
        path={appRoutersAdministration.administrationReports}
        Component={AdministrationReports}
        requiredRoles={['admin_phr']}
      />
      <Route exact path={appRoutersAdministration.administration}>
        <Redirect to={appRoutersAdministration.administrationPanel} />;
      </Route>
      <Route exact path={appRoutersAdministration.administrationTrainings}>
        <Redirect
          to={appRoutersAdministration.administrationAcceptedTrainings}
        />
        ;
      </Route>
    </Switch>
  );
};

export default appRoutes;
