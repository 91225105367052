import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  Box,
  ContentPlansTrainings,
  LoaderTable,
  CustomContainer,
} from 'components';
import { IDPRepository } from 'api/repository';
import CatalogsPlansIDP from './CatalogsPlansIDP';

const PlansReportsIDP = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [getData, data] = IDPRepository.useGetAllPlansIDP();

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={loaderTable} radiusTop allowGrow>
          <Box
            id="plansReportsBoxIDP"
            name={t('planBox.nameBoxGenerateReportsIDP')}
            svgIcon="addEdit"
            noContentStyle
          >
            <ContentPlansTrainings>
              <>
                {data && data !== null ? (
                  <CatalogsPlansIDP
                    id="CatalogsTrainingsItemsIDP"
                    data={data}
                  />
                ) : (
                  ''
                )}
              </>
            </ContentPlansTrainings>
          </Box>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default PlansReportsIDP;
