import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  CustomContainer,
  LoaderTable,
  Modal,
  Tabs,
  TabsHook,
} from 'components';
import { IDPRepository } from 'api/repository';
import { IDPModel } from 'api/types';

import IDPEmployeeDetail from './IDPEmployeeDetail';

import IDPEmployeeArea from './IDPEmployeeArea';
import IDPEmployeeLeaderProfile from './IDPEmployeeLeaderProfile';
import IDPFeedback from './IDPFeedback';

import './IDPDetail.scss';

interface Props {
  idpId: number;
  idEmployee: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  selectedYear?: string;
  employeeName?: string;
  settingsIdp?: IDPModel | null;
}

const EditAcceptedTrainings = (props: Props): ReactElement => {
  const {
    idpId,
    idEmployee,
    changeModalEdit,
    selectedYear,
    employeeName,
    settingsIdp,
  } = props;
  const [IDPEmployee, fetchData] = IDPRepository.useGetIDPEmployeeDataAdmin({
    id: idEmployee,
    idpId,
  });

  const [action, setAction] = useState<string>('dane-podstawowe');

  const nameBox = () => {
    return `EDYCJA IDP - ${selectedYear} - ${employeeName}`;
  };

  const cancel = () => {
    changeModalEdit(false);
  };

  return (
    <>
      <Modal open changeModalShow={cancel} widthBox="high">
        <CustomContainer>
          <LoaderTable active={IDPEmployee === undefined}>
            <Box
              id="IDPEmployeeBox"
              name={nameBox()}
              svgIcon="addEdit"
              noPadding
            >
              <>
                <>
                  <TabsHook
                    id="IDPEmployeeTabs"
                    items={[
                      {
                        id: 'dane-podstawowe',
                        label: 'Cele, komentarze, mobilność',

                        svgName: 'target',
                      },
                      {
                        id: 'obszary-rozwoju',
                        label: 'Obszary rozwoju',

                        svgName: 'searchAndGraph',
                      },
                      /* { 
                        id: 'profil-lidera',
                        label: 'Profil lidera',

                        svgName: 'employeeWithStar',
                      }, */
                      {
                        id: 'feedback-nt-wartosci',
                        label: 'Feedback nt wartości',

                        svgName: 'feedback',
                      },
                    ]}
                    activeTab={action}
                    setActiveTab={setAction}
                  />
                  <div id="IDPEmployeeContentBox">
                    {action === 'dane-podstawowe' &&
                    IDPEmployee &&
                    IDPEmployee !== null ? (
                      <IDPEmployeeDetail
                        IDPEmployee={IDPEmployee}
                        idEmployee={idEmployee}
                        changeModalEdit={changeModalEdit}
                      />
                    ) : (
                      ''
                    )}
                    {action === 'obszary-rozwoju' &&
                    IDPEmployee &&
                    IDPEmployee !== null ? (
                      <IDPEmployeeArea
                        IDPEmployee={IDPEmployee}
                        fetchData={fetchData}
                        idEmployee={idEmployee}
                        changeModalEdit={changeModalEdit}
                        settingsIdp={settingsIdp}
                      />
                    ) : (
                      ''
                    )}
                    {/* action === 'profil-lidera' && IDPEmployee ? (
                      <IDPEmployeeLeaderProfile
                        IDPEmployee={IDPEmployee}
                        idEmployee={idEmployee}
                        fetchData={fetchData}
                        changeModalEdit={changeModalEdit}
                      />
                    ) : (
                      ''
                    ) */}
                    {action === 'feedback-nt-wartosci' && IDPEmployee ? (
                      <IDPFeedback
                        IDPEmployee={IDPEmployee}
                        idEmployee={idEmployee}
                        changeModalEdit={changeModalEdit}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </>
              </>
            </Box>
          </LoaderTable>
        </CustomContainer>
      </Modal>
    </>
  );
};

export default EditAcceptedTrainings;
