/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState, useEffect } from 'react';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';
import SearchBox from '../ComponentFilter/SearchBox';
import SearchText from '../ComponentFilter/SearchText';
import '../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  customFilter: any;
  column?: any;
  alignBox?: 'left' | 'center' | 'right';
  name?: string;
  email?: string;
}

const CustomFilterContact = (props: Props): ReactElement => {
  const { t } = useTranslation('filterTable');

  const myRef = React.createRef();

  const { customFilter, column, alignBox, name, email } = props;

  const [openSearch, setOpenSearch] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchName, setSearchName] = useState(name || '');
  const [searchEmail, setSearchEmail] = useState(email || '');

  useEffect(() => {
    if (searchName === '' && searchEmail === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }
  }, []);

  const handleSearch = () => {
    if (searchName === '' && searchEmail === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }
    customFilter({
      contactName: searchName,
      contactEmail: searchEmail,
    });
    setOpenSearch(false);
  };

  // Reset filter
  const [resetFilter, setResetFilter] = useState(0);
  useEffect(() => {
    if (resetFilter !== 0) {
      handleSearch();
    }
  }, [resetFilter]);

  const handleSearchClear = () => {
    setSearchName('');
    setSearchEmail('');
    setResetFilter(Math.random);
  };
  // End reset filter

  const keyAction = (e: any) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <SearchBox
      handleSearch={handleSearch}
      labelSearch={t('filterContact.search')}
      openSearch={openSearch}
      activeSearch={activeSearch}
      alignBox={alignBox}
      handleSearchClear={handleSearchClear}
    >
      <>
        <SearchText
          name="name"
          nameLabel={t('filterContact.name')}
          placeholder={t('filterContact.namePlaceholder')}
          setValue={setSearchName}
          keyAction={keyAction}
          setOpenSearch={setOpenSearch}
          value={searchName}
        />
        <SearchText
          name="email"
          nameLabel={t('filterContact.email')}
          placeholder={t('filterContact.emailPlaceholder')}
          setValue={setSearchEmail}
          keyAction={keyAction}
          setOpenSearch={setOpenSearch}
          value={searchEmail}
        />
      </>
    </SearchBox>
  );
};

export default CustomFilterContact;
