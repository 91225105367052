import React, { ReactElement, useState, ElementType, useEffect } from 'react';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import './CustomMultiSelect.scss';
import { RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  name?: string;
  options?: any[];
  value?: any;
  onChange?: (e: any) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  className?: string;
  isSearchable?: boolean;
  error?: string | undefined;
  onlyView?: boolean;
  label?: string;
  info?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  setValue?: any;
  valueById?: any;
  onNewRecord?: any;
}

const CustomMultiSelect = (props: Props): ReactElement => {
  const {
    options,
    onChange,
    menuPlacement,
    value,
    className,
    isSearchable,
    name,
    error,
    onlyView,
    label,
    info,
    disabled,
    fullWidth,
    placeholder,
    setValue,
    valueById,
    onNewRecord,
  } = props;

  const [selectValue, setSelectValue] = useState<any>(null);

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  useEffect(() => {
    if (selectValue === null) {
      if (valueById !== undefined && valueById.length > 0) {
        if (options !== undefined && options.length > 0) {
          const selectedValue: any[] = [];
          Object.keys(valueById).forEach((key) => {
            if (
              options.filter(
                (option: any) => option.value === valueById[key].id
              )[0] !== undefined
            ) {
              selectedValue.push(
                options.filter(
                  (option: any) => option.value === valueById[key].id
                )[0]
              );
            }
          });
          if (selectedValue.length > 0) {
            setSelectValue(selectedValue);
          }
        }
      }
    }
  }, [options, selectValue, valueById]);

  useEffect(() => {
    if (selectValue !== undefined && selectValue !== null) {
      const newValue: string | number[] = [];
      Object.keys(selectValue).forEach((key) => {
        newValue.push(selectValue[key].value);
      });
      setValue(name, newValue);
    }
  }, [name, selectValue, setValue]);

  return (
    <>
      <div>
        <div
          className={`multiSelectBlock ${label ? 'withLabel' : ''} ${
            fullWidth ? 'fullWidth' : ''
          } ${info ? 'withInfo' : ''}`}
        >
          {label ? <Form.Label id={name}>{label}</Form.Label> : ''}
          <div className="additionalInfo">{errorElement()}</div>
          <div className="blockInputWithInfo">
            <Select
              placeholder={placeholder}
              required
              isMulti
              name={name}
              className={className || 'multiSelect'}
              classNamePrefix="multiSelect"
              options={options || []}
              value={value !== undefined ? value() : selectValue}
              onChange={(e, t) => {
                if (e !== null && onChange !== undefined) {
                  onChange(e);
                } else if (t.action === 'select-option') {
                  if (
                    // @ts-ignore
                    t.option.value === 'new'
                  ) {
                    if (onNewRecord) {
                      onNewRecord();
                    }
                  } else {
                    setSelectValue(e);
                  }
                } else {
                  setSelectValue(e);
                }
              }}
              menuPlacement={
                menuPlacement !== undefined ? menuPlacement : 'auto'
              }
              isSearchable={isSearchable || false}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F1F6F5',
                  primary: '#1D4370',
                },
              })}
            />
            {infoElement()}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomMultiSelect;
