/* eslint-disable react/jsx-no-bind */
import React, { ReactElement, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TrainingsProviderContactRepository } from 'api/repository';
import {
  Box,
  TableRecordAction,
  TableCellDefault,
  TableCellContact,
  TablePagination,
  LoaderTable,
} from 'components';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalContactAdd from './ModalContactAdd';
import ModalContactShow from './ModalContactShow';
import ModalContactEdit from './ModalContactEdit';

interface Props {
  id: number;
  onlyView?: boolean;
}

const TableAdress = (props: Props): ReactElement => {
  const { t } = useTranslation('trainingsProvider');
  const { id, onlyView } = props;
  const page = 1;
  const rowsPerPage = 1;
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [
    getData,
    data,
  ] = TrainingsProviderContactRepository.useGetAllTrainingsProviderContact({
    id,
  });

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  useEffect(() => {
    setLoaderTable(true);
    getData({
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    });
  }, [getData, page, rowsPerPage, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleEditClick = (idClick: number) => {
    setModalId(idClick);
    setModalEdit(true);
  };

  const DeleteRecord = TrainingsProviderContactRepository.useDeleteTrainingsProviderContact(
    id,
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const HandleDeleteClick = (idClick: number) => {
    setModalId(idClick);
    DeleteRecord(idClick);
  };

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        if (onlyView) {
          return (
            <TableRecordAction
              dataId={row.id}
              showClick={handleShowClick}
              rowNumber={rowIndex}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          );
        }
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={handleShowClick}
            editClick={handleEditClick}
            archiveClick={HandleDeleteClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('contact.name'),
    },
    {
      dataField: '',
      text: t('contact.contact'),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellContact data={row} />;
      },
    },
    {
      dataField: '',
      text: t('contact.defaultContact'),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return <TableCellDefault recordDefault={row.main} />;
      },
    },
  ];

  return (
    <>
      <Box
        id="trainingProviderBox"
        name={t('contact.CONTACT')}
        svgIcon="contact"
        lightHeader
        allowHideContent
        noPadding
        noRadius={onlyView}
      >
        <LoaderTable active={loaderTable}>
          <>
            {!onlyView ? (
              <TablePagination>
                <Button className="green" onClick={() => handleOpenAddForm()}>
                  {t('contact.addContact')}
                </Button>
              </TablePagination>
            ) : (
              ''
            )}
            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={data?.content || []}
              columns={columns}
              noDataIndication={t('contact.noData')}
              remote={{
                filter: true,
                sort: true,
              }}
            />
          </>
        </LoaderTable>
      </Box>

      {modalShow ? (
        <ModalContactShow
          id={id}
          idRecord={modalId}
          changeModalShow={setModalShow}
        />
      ) : (
        ''
      )}
      {modalAdd ? (
        <ModalContactAdd
          id={id}
          changeModalAdd={setModalAdd}
          changeModalEdit={setModalEdit}
          changeModalId={setModalId}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
      {modalEdit ? (
        <ModalContactEdit
          id={id}
          idRecord={modalId}
          changeModalEdit={setModalEdit}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TableAdress;
