import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import {
  Box,
  CatalogsTrainings,
  ContentCatalogsTrainings,
  ActionBeforeCatalog,
  LoaderTable,
  CustomContainer,
} from 'components';
import { CatalogsTrainingsRepository } from 'api/repository';
import ModalCatalogsTrainingsAdd from './ModalCatalogsTrainingsAdd';
import ModalCatalogsTrainingsEdit from './ModalCatalogsTrainingsEdit';
import ModalCatalogsTrainingsShow from './ModalCatalogsTrainingsShow';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('catalogsTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [
    getData,
    data,
  ] = CatalogsTrainingsRepository.useGetAllCatalogsTrainings();

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleEditClick = (idClick: number) => {
    setModalId(idClick);
    setModalEdit(true);
  };

  const DeleteRecord = CatalogsTrainingsRepository.useDeleteCatalogsTrainings(
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const HandleDeleteClick = (idClick: number) => {
    setModalId(idClick);
    DeleteRecord(idClick);
  };

  return (
    <>
      <CustomContainer>
        <LoaderTable active={loaderTable} radiusTop>
          <Box
            id="catalogsTrainingsBox"
            name={t('catalogBox.nameBox')}
            svgIcon="catalog"
            noContentStyle
          >
            <ContentCatalogsTrainings>
              <>
                <ActionBeforeCatalog id="actionBeforeCatalogsTrainings">
                  <Button className="green" onClick={() => handleOpenAddForm()}>
                    {t('catalogBox.addCatalog')}
                  </Button>
                </ActionBeforeCatalog>
                <CatalogsTrainings
                  id="CatalogsTrainingsItems"
                  data={data}
                  // showClick={handleShowClick}
                  editClick={handleEditClick}
                  archiveClick={HandleDeleteClick}
                />
              </>
            </ContentCatalogsTrainings>
          </Box>
        </LoaderTable>
      </CustomContainer>
      {modalAdd ? (
        <ModalCatalogsTrainingsAdd
          changeModalAdd={setModalAdd}
          changeModalEdit={setModalEdit}
          changeModalId={setModalId}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
      {modalEdit ? (
        <ModalCatalogsTrainingsEdit
          id={modalId}
          changeModalEdit={setModalEdit}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
      {modalShow ? (
        <ModalCatalogsTrainingsShow
          id={modalId}
          changeModalShow={setModalShow}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Trainigns;
