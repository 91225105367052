import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  TrainingsCompanyContactRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import {
  EmployeeAppraisalFeedback180Form,
  EmployeeAppraisalFeedback180Model,
  IDPEmployeeAddAndPut,
  IDPEmployeeModel,
  IDPFeedbackForm,
  IDPFeedbackModel,
  TrainingsGroupTraining,
  TrainingsGroupTrainingAddPut,
} from 'api/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  CustomContainer,
  ContentBox,
  RenderSvg,
  EditorMceShort,
} from 'components';

import './feedback180.scss';

type DataValues = EmployeeAppraisalFeedback180Form;

interface TrainingsGroupTrainingProps {
  id?: number;
  onTrainingsProvider?: any;
  onTrainingsProviderAndExit?: any;
  data?: EmployeeAppraisalFeedback180Model | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
  cancel?: () => void;
}

const FormTrainingsProvider = (
  props: TrainingsGroupTrainingProps
): ReactElement => {
  const {
    id,
    onTrainingsProvider,
    edit,
    data,
    onlyView,
    onTrainingsProviderAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const { t } = useTranslation('trainingsGroup');

  const formSchema: yup.SchemaOf<DataValues> = yup.object().shape({
    CORRECT: yup.string().required('Pole wymagane'),
    CONTINUE: yup.string().required('Pole wymagane'),
    STOP: yup.string().required('Pole wymagane'),
    INTRODUCE: yup.string().required('Pole wymagane'),
  });

  const { handleSubmit, register, errors } = useForm<DataValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSave = (dataSubmit: DataValues) => {
    setChangeForm(false);
    onTrainingsProvider(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: DataValues) => {
    setChangeForm(false);
    onTrainingsProviderAndExit(dataSubmit);
  };

  return (
    <CustomContainer id="formEmployeeAppraisalFeedbackBox">
      <>
        {data === undefined ? (
          <ErrorLoadData />
        ) : (
          <>
            <ExitFromForm changeForm={changeForm} />

            <Form
              className="form"
              id="formEmployeeAppraisalFeedback"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={data === undefined && edit} type="getData">
                <>
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      <ContentBox
                        id="formEmployeeAppraisalFeedbackFirstRow"
                        bottomBorder
                      >
                        <div id="formEmployeeAppraisalFeedbackFirstRowBox">
                          <div id="formEmployeeAppraisalFeedbackFirstRowBoxIcon">
                            <RenderSvg nameSvg="feedback180-60" />
                          </div>
                          <div id="formEmployeeAppraisalFeedbackFirstRowBoxInfo">
                            <div id="formEmployeeAppraisalFeedbackFirstRowBoxInfoLabel">
                              Feedback 180
                            </div>
                            <div id="formEmployeeAppraisalFeedbackFirstRowBoxInfoText">
                              Przekaż Przełożonemu informację zwrotną w
                              poniższych obszarach.
                              <br />
                              Wskaż, co zasługuje na uznanie, a co wymaga
                              poprawy.
                            </div>
                          </div>
                        </div>
                      </ContentBox>
                      <Box
                        lightHeader
                        name="Informacja zwrotna:"
                        id="IDPFeedbackBox"
                        noRadius
                      >
                        <>
                          <div id="correctBox" className="feedbackBoxItem">
                            <div id="correctBoxAccent" className="boxAccent" />
                            <div
                              id="correctBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div id="correctBoxTitle" className="boxTitle">
                                Popraw
                              </div>
                              <div
                                id="correctBoxSubTitle"
                                className="boxSubTitle"
                              >
                                np. obciążenie pracą, dzielenie się
                                informacjami...
                              </div>
                            </div>
                            <div id="correctBoxForm" className="boxForm">
                              <EditorMceShort
                                id="CORRECT"
                                name="CORRECT"
                                setHeight={120}
                                placeholder="Wpisz co należy poprawić"
                                register={register}
                                contentGet={
                                  data?.feedbackValues?.CORRECT.description
                                }
                                error={errors?.CORRECT?.message}
                                required
                                onlyView={onlyView}
                              />
                            </div>
                          </div>

                          <div id="continueBox" className="feedbackBoxItem">
                            <div id="continueBoxAccent" className="boxAccent" />
                            <div
                              id="continueBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div id="continueBoxTitle" className="boxTitle">
                                Kontynuuj
                              </div>
                              <div
                                id="continueBoxSubTitle"
                                className="boxSubTitle"
                              >
                                np. słuchanie, dobre relacje...
                              </div>
                            </div>
                            <div id="continueBoxForm" className="boxForm">
                              <EditorMceShort
                                id="CONTINUE"
                                name="CONTINUE"
                                setHeight={120}
                                placeholder="Wpisz co należy kontynuować"
                                register={register}
                                contentGet={
                                  data?.feedbackValues?.CONTINUE.description
                                }
                                error={errors?.CONTINUE?.message}
                                required
                                onlyView={onlyView}
                              />
                            </div>
                          </div>

                          <div id="stopBox" className="feedbackBoxItem">
                            <div id="stopBoxAccent" className="boxAccent" />
                            <div id="stopBoxTitleBox" className="boxTitleBox">
                              <div id="stopBoxTitle" className="boxTitle">
                                Zaprzestań
                              </div>
                              <div id="stopBoxSubTitle" className="boxSubTitle">
                                np. nieporozumienia...
                              </div>
                            </div>
                            <div id="stopBoxForm" className="boxForm">
                              <EditorMceShort
                                id="STOP"
                                name="STOP"
                                setHeight={120}
                                placeholder="Wpisz czego należy zaprzestać"
                                register={register}
                                contentGet={
                                  data?.feedbackValues?.STOP.description
                                }
                                error={errors?.STOP?.message}
                                required
                                onlyView={onlyView}
                              />
                            </div>
                          </div>

                          <div id="introduceBox" className="feedbackBoxItem">
                            <div
                              id="introduceBoxAccent"
                              className="boxAccent"
                            />
                            <div
                              id="introduceBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div id="introduceBoxTitle" className="boxTitle">
                                Wprowadź
                              </div>
                              <div
                                id="introduceBoxSubTitle"
                                className="boxSubTitle"
                              >
                                np. organizacja spotkań 1to1, kontrola
                                projektów...
                              </div>
                            </div>
                            <div id="introduceBoxForm" className="boxForm">
                              <EditorMceShort
                                id="INTRODUCE"
                                name="INTRODUCE"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań zgodnych"
                                register={register}
                                contentGet={
                                  data?.feedbackValues?.INTRODUCE.description
                                }
                                error={errors?.INTRODUCE?.message}
                                required
                                onlyView={onlyView}
                              />
                            </div>
                          </div>
                        </>
                      </Box>
                    </>
                  </LoaderForm>
                  {!onlyView ? (
                    <CustomSubmit
                      save={
                        onTrainingsProvider
                          ? handleSubmit(handleSave)
                          : undefined
                      }
                      saveAndExit={
                        onTrainingsProviderAndExit
                          ? handleSubmit(handleSaveAndExit)
                          : undefined
                      }
                      submitForm={submitForm}
                      cancel={cancel}
                    />
                  ) : (
                    ''
                  )}
                </>
              </LoaderForm>
            </Form>
          </>
        )}
      </>
    </CustomContainer>
  );
};

export default FormTrainingsProvider;
