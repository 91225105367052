import { AuthResources, Box, CustomContainer, DesktopItem } from 'components';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './reports.scss';
import ModalTrainings from './Trainings/ModalTrainings';
import ModalEmployeeAppraisal from './EmployeeAppraisal';
import ModalIDP from './IDP';

const PanelReports = (): ReactElement => {
  const history = useHistory();

  /// TRAINING START
  const [modalTraining, setModalTraining] = useState<boolean>(false);
  const [modalIDP, setModalIDP] = useState<boolean>(false);
  const [modalEmployeeAppraisal, setModalEmployeeAppraisal] = useState<boolean>(
    false
  );

  const setButtonTraining = () => {
    return {
      text: 'Raporty',
      action: () => setModalTraining(true),
    };
  };
  const setContentTraining = () => {
    return (
      <>
        <div>W tej sekcji możesz wygenerować raporty dla szkoleń</div>
      </>
    );
  };

  /// TRAINING END

  /// IDP START

  const setButtonIDP = () => {
    return {
      text: 'Raporty',
      action: () => {
        setModalIDP(true);
      },
    };
  };

  const setContentIDP = () => {
    return (
      <>
        <div>W tej sekcji możesz wygenerować raporty dla IDP</div>
      </>
    );
  };

  /// IDP END

  /// Appraisal START

  const setButtonAppraisal = () => {
    return {
      text: 'Raporty',
      action: () => {
        setModalEmployeeAppraisal(true);
      },
    };
  };

  const setContentAppraisal = () => {
    return (
      <>
        <div>W tej sekcji możesz wygenerować raporty dla ocen pracowników</div>
      </>
    );
  };

  /// Appraisal END

  return (
    <CustomContainer allowGrow>
      <>
        <Box
          id="administrationReportsDesktop"
          name="PULPIT ADMINISTRACYJNY"
          svgIcon="desktop"
          noContentStyle
        >
          <div id="administrationReportsDesktopBox">
            <div id="administrationReportsDesktopBoxContent">
              <AuthResources requiredRoles={['admin_phr']}>
                <DesktopItem
                  id="trainings"
                  name="SZKOLENIA"
                  buttonTitle={setButtonTraining()?.text}
                  buttonAction={setButtonTraining()?.action}
                >
                  <>{setContentTraining()}</>
                </DesktopItem>
              </AuthResources>
              <AuthResources requiredRoles={['admin_phr']}>
                <DesktopItem
                  id="idp"
                  name="IDP"
                  buttonTitle={setButtonIDP()?.text}
                  buttonAction={setButtonIDP()?.action}
                >
                  <>{setContentIDP()}</>
                </DesktopItem>
              </AuthResources>
              <AuthResources requiredRoles={['admin_phr']}>
                <DesktopItem
                  id="appraisal"
                  name="OCENA PRACOWNIKA"
                  buttonTitle={setButtonAppraisal()?.text}
                  buttonAction={setButtonAppraisal()?.action}
                >
                  <>{setContentAppraisal()}</>
                </DesktopItem>
              </AuthResources>
            </div>
          </div>
        </Box>
        {modalTraining ? (
          <ModalTrainings changeModalTrainings={setModalTraining} />
        ) : (
          ''
        )}
        {modalIDP ? <ModalIDP changeModalIDP={setModalIDP} /> : ''}
        {modalEmployeeAppraisal ? (
          <ModalEmployeeAppraisal
            changeModalEmployeeAppraisal={setModalEmployeeAppraisal}
          />
        ) : (
          ''
        )}
      </>
    </CustomContainer>
  );
};

export default PanelReports;
