import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import PlansIDP from './PlansIDP';
// import CatalogsPlans from './CatalogsPlans';
// import PlansTrainingsShow from './PlansTrainingsShow';

interface Props {
  changeModalIDP: Dispatch<SetStateAction<boolean>>;
}

const modalIDP = (props: Props): ReactElement => {
  const { changeModalIDP } = props;

  return (
    <>
      <Modal
        open
        changeModalShow={changeModalIDP}
        widthBox="high"
        maxLevel="lvl2"
      >
        <PlansIDP />
      </Modal>
    </>
  );
};

export default modalIDP;
