import React, { ReactElement, useState, ElementType, useEffect } from 'react';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import './CustomSelect.scss';
import { RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface SwitchProps {
  name?: string;
  options: any;
  value?: any;
  onChange?: (e: any) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  className?: string;
  isSearchable?: boolean;
  error?: string | undefined;
  onlyView?: boolean;
  label?: string;
  info?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  setValue?: any;
  valueByKey?: string | number;
  required?: boolean;
  onNewRecord?: any;
  noOptions?: string;
  height33?: boolean;
  noInfo?: boolean;
  menuIsOpen?: boolean;
  clearValue?: number;
  validRequiredSelect?: () => void;
}

const CustomInput = (props: SwitchProps): ReactElement => {
  const {
    options,
    onChange,
    menuPlacement,
    value,
    className,
    isSearchable,
    name,
    error,
    onlyView,
    label,
    info,
    disabled,
    fullWidth,
    placeholder,
    setValue,
    valueByKey,
    required,
    onNewRecord,
    noOptions,
    height33,
    noInfo,
    menuIsOpen,
    clearValue,
    validRequiredSelect,
  } = props;

  const [selectValue, setSelectValue] = useState<
    | {
        [key: string]: string;
      }
    | null
    | undefined
  >(undefined);

  const [clear, setClear] = useState<number | undefined>(undefined);

  const [updateDefault, setUpdateDefault] = useState<boolean>(false);

  useEffect(() => {
    if (clearValue !== undefined && selectValue !== undefined) {
      setClear(clearValue);
    }
  }, [clearValue]);

  useEffect(() => {
    if (clear !== undefined) {
      setSelectValue(null);
      if (setValue) {
        setValue(name, null);
      }
    }
  }, [clear]);

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  useEffect(() => {
    if (selectValue === undefined) {
      if (valueByKey && valueByKey !== null && updateDefault === false) {
        setUpdateDefault(true);
        setSelectValue(
          options.filter(
            (option: { [key: string]: string }) => option.value === valueByKey
          )[0]
        );
      }
    }
  }, [options, selectValue, updateDefault, valueByKey]);

  useEffect(() => {
    if (
      selectValue !== undefined &&
      selectValue !== null &&
      setValue !== undefined
    ) {
      if (selectValue.value !== undefined) {
        setValue(name, selectValue.value);
        if (error && required && validRequiredSelect) {
          validRequiredSelect();
        }
      }
    }
  }, [name, selectValue, setValue]);

  return (
    <div
      id={`${name}SelectBlock`}
      className={`selectBlock ${label ? 'withLabel' : ''} ${
        fullWidth ? 'fullWidth' : ''
      } ${info ? 'withInfo' : ''} ${required ? 'required' : ''} ${
        error ? 'error' : ''
      } ${height33 ? 'height33' : ''} ${noInfo ? 'noInfo' : ''}`}
    >
      {label ? <Form.Label id={name}>{label}</Form.Label> : ''}
      <div className="blockInputWithInfo">
        {disabled || onlyView ? (
          <div id={name} className="disabledSelect">
            {selectValue ? selectValue?.label : ''}
          </div>
        ) : (
          <Select
            placeholder={placeholder || 'Wybierz...'}
            required={required}
            name={name}
            className={className || 'selectCustom'}
            classNamePrefix={className || 'selectCustom'}
            options={options}
            value={value !== undefined ? value() : selectValue}
            onChange={(e) => {
              if (e !== null && onChange !== undefined) {
                onChange(e);
              } else if (e.value === 'new') {
                if (onNewRecord) {
                  onNewRecord();
                }
                setSelectValue(null);
              } else {
                setSelectValue(e);
              }
            }}
            menuPlacement={menuPlacement !== undefined ? menuPlacement : 'auto'}
            isSearchable={isSearchable || false}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#F1F6F5',
                primary: '#1D4370',
              },
            })}
            noOptionsMessage={() => noOptions || 'Brak danych'}
            menuIsOpen={menuIsOpen}
          />
        )}
        {infoElement()}
      </div>
      <div className="additionalInfo">{errorElement()}</div>
    </div>
  );
};

export default CustomInput;
