/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState, ReactNode } from 'react';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';
import SearchBox from '../ComponentFilter/SearchBox';
import SearchText from '../ComponentFilter/SearchText';
import '../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  customFilter: any;
  column?: any;
  alignBox?: 'left' | 'center' | 'right';
  firstNameDefault?: string;
  lastNameDefault?: string;
}

const CustomFilterLastFirstName = (props: Props): ReactElement => {
  const { t } = useTranslation('filterTable');

  const {
    customFilter,
    column,
    alignBox,
    firstNameDefault,
    lastNameDefault,
  } = props;
  const [openSearch, setOpenSearch] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchFirst, setSearchFirst] = useState(firstNameDefault || '');
  const [searchLast, setSearchLast] = useState(lastNameDefault || '');

  useEffect(() => {
    if (searchFirst === '' && searchLast === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }
  }, []);

  const handleSearch = () => {
    if (searchFirst === '' && searchLast === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }

    customFilter({
      firstName: searchFirst,
      lastName: searchLast,
    });
    setOpenSearch(false);
  };
  // Reset filter
  const [resetFilter, setResetFilter] = useState(0);
  useEffect(() => {
    if (resetFilter !== 0) {
      handleSearch();
    }
  }, [resetFilter]);

  const handleSearchClear = () => {
    setSearchFirst('');
    setSearchLast('');
    setResetFilter(Math.random);
  };
  // End reset filter

  const keyAction = (e: any) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <SearchBox
      handleSearch={handleSearch}
      labelSearch={t('filterLastFirstName.search')}
      openSearch={openSearch}
      activeSearch={activeSearch}
      alignBox={alignBox}
      handleSearchClear={handleSearchClear}
    >
      <>
        <SearchText
          name="lastName"
          nameLabel={t('filterLastFirstName.lastName')}
          placeholder={t('filterLastFirstName.lastNamePlaceholder')}
          setValue={setSearchLast}
          keyAction={keyAction}
          setOpenSearch={setOpenSearch}
          value={searchLast}
        />
        <SearchText
          name="firstName"
          nameLabel={t('filterLastFirstName.firstName')}
          placeholder={t('filterLastFirstName.firstNamePlaceholder')}
          setValue={setSearchFirst}
          keyAction={keyAction}
          setOpenSearch={setOpenSearch}
          value={searchFirst}
        />
      </>
    </SearchBox>
  );
};

export default CustomFilterLastFirstName;
