import Keycloak from 'keycloak-js';

const serverOrigin = process.env.REACT_APP_IDENTITY;

const gatewayPort = process.env.REACT_APP_IDENTITY_PORT;

const apiUrl = () => {
  return `${serverOrigin}${
    process.env.NODE_ENV.includes('development') ? ':' : ''
  }${process.env.NODE_ENV.includes('development') ? gatewayPort : ''}/auth`;
};

const keycloak = Keycloak({
  url: apiUrl(),
  realm: 'grupaPomocnik',
  clientId: `react-app`,
});

export default keycloak;
