/* eslint-disable react/jsx-no-bind */
import React, { ReactElement, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TrainingsProviderAddressRepository } from 'api/repository';
import {
  Box,
  TableRecordAction,
  TableCellDefault,
  TableCellAddress,
  TablePagination,
  LoaderTable,
} from 'components';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalAddressAdd from './ModalAddressAdd';
import ModalAddressShow from './ModalAddressShow';
import ModalAddressEdit from './ModalAddressEdit';

interface Props {
  id: number;
  onlyView?: boolean;
}

const TableAdress = (props: Props): ReactElement => {
  const { t } = useTranslation('trainingsProvider');
  const { id, onlyView } = props;
  const page = 1;
  const rowsPerPage = 100;
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [
    getData,
    data,
  ] = TrainingsProviderAddressRepository.useGetAllTrainingsProviderAddress({
    id: Number(id),
  });

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  useEffect(() => {}, [modalId]);

  useEffect(() => {
    setLoaderTable(true);
    getData({
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    });
  }, [getData, page, rowsPerPage, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleEditClick = (idClick: number) => {
    setModalId(idClick);
    setModalEdit(true);
  };

  const DeleteRecord = TrainingsProviderAddressRepository.useDeleteTrainingsProviderAddress(
    id,
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const HandleDeleteClick = (idClick: number) => {
    setModalId(idClick);
    DeleteRecord(idClick);
  };

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        if (onlyView) {
          return (
            <TableRecordAction
              dataId={row.address.id}
              showClick={handleShowClick}
              rowNumber={rowIndex}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          );
        }
        return (
          <TableRecordAction
            dataId={row.address.id}
            showClick={handleShowClick}
            editClick={handleEditClick}
            archiveClick={HandleDeleteClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'address.name',
      text: t('address.name'),
    },
    {
      dataField: '',
      text: t('address.address'),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellAddress data={row.address} />;
      },
    },
    {
      dataField: '',
      text: t('address.defaultAddress'),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return <TableCellDefault recordDefault={row.main} />;
      },
    },
  ];

  return (
    <>
      <Box
        id="trainingProviderBox"
        name={t('address.address')}
        svgIcon="map-marker"
        lightHeader
        allowHideContent
        noRadius={onlyView}
        noPadding
      >
        <LoaderTable active={loaderTable}>
          <>
            {!onlyView ? (
              <TablePagination>
                <Button className="green" onClick={() => handleOpenAddForm()}>
                  {t('address.addAddress')}
                </Button>
              </TablePagination>
            ) : (
              ''
            )}
            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={data?.content || []}
              columns={columns}
              noDataIndication={t('address.noData')}
              remote={{
                filter: true,
                sort: true,
              }}
            />
          </>
        </LoaderTable>
      </Box>
      {modalShow ? (
        <ModalAddressShow
          id={id}
          idRecord={modalId}
          changeModalShow={setModalShow}
        />
      ) : (
        ''
      )}
      {modalAdd ? (
        <ModalAddressAdd
          id={id}
          changeModalAdd={setModalAdd}
          changeModalEdit={setModalEdit}
          changeModalId={setModalId}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
      {modalEdit ? (
        <ModalAddressEdit
          id={id}
          idRecord={modalId}
          changeModalEdit={setModalEdit}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TableAdress;
