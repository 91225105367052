import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from 'utils/keycloak';
import 'scss/style.scss';
import '../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './store';

import './i18n';

/* const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '5px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
}; */

ReactDOM.render(
  <>
    <ReactKeycloakProvider authClient={keycloak}>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </ReactKeycloakProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
