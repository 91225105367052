import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ContentBox,
  CustomFilterData,
  CustomFilterSelect,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  RenderSvg,
  ShowTitleTable,
  TableCellTypeTrainings,
  TablePagination,
  TableRecordAction,
} from 'components';
import { TrainingsGroupRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { useHistory } from 'react-router-dom';
import TrainingsType from 'constants/Trainings';
import { Button } from 'react-bootstrap';
import './tableTrainingsBlock.scss';
import { convertStringDatetoShortDate } from 'utils';

const TableTrainingsBlock = (): ReactElement => {
  const { t, ready } = useTranslation(['trainingsGroup', 'trainings']);
  const history = useHistory();

  const getStorageTableData = () => {
    const data = window.sessionStorage.getItem(
      'managerUserPlansTrainingsGroupEmployeeTable'
    );
    if (data === null) {
      return null;
    }
    return JSON.parse(data);
  };

  const storageTable = getStorageTableData();

  const [page, setPage] = React.useState(
    storageTable === null ? 1 : storageTable.pageNumber + 1
  );
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  // variable search

  const mapSearch: { [key: string]: string } = {
    'training.name': 'name',
    'training.type': 'type',
  };

  const getFilterData = (): { [key: string]: string } => {
    if (storageTable === null) {
      return {};
    }
    let newSort = {} as { [key: string]: string };
    Object.keys(mapSearch).forEach((v) => {
      if (storageTable?.[mapSearch?.[v]]) {
        newSort = {
          ...newSort,
          [mapSearch?.[v]]: storageTable?.[mapSearch?.[v]],
        };
      }
    });
    return newSort;
  };
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>(
    getFilterData()
  );

  // End variable search

  // variable order

  const getOrderData = (): string => {
    if (storageTable === null) {
      return JSON.stringify({
        PROVIDER_NAME: 'default',
        order: 'asc',
      });
    }

    let newOrder = {
      PROVIDER_NAME: 'default',
      order: storageTable.ascending ? 'asc' : 'desc',
    } as { [key: string]: string };
    if (storageTable.sort) {
      newOrder = { ...newOrder, name: storageTable.sort };
    }
    return JSON.stringify(newOrder);
  };

  const [orderColumn, setOrderColumn] = useState<string>(getOrderData());
  const mapOrder: { [key: string]: string } = {
    'training.name': 'default',
    'training.type': 'TYPE',
  };

  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [getData, data] = TrainingsGroupRepository.useGetAllTrainings();

  const [
    dataSendNotification,
    fetchDataSendNotification,
  ] = TrainingsGroupRepository.useGetLastSendNotification();

  const sendNotification = TrainingsGroupRepository.useSendNotification(
    async () => {
      fetchDataSendNotification();
    }
  );

  const handleClickSentNotification = () => {
    sendNotification();
  };

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  useEffect(() => {
    setLoaderTable(true);

    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }

    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }
    window.sessionStorage.setItem(
      'managerUserPlansTrainingsGroupEmployeeTable',
      JSON.stringify(paramQuery)
    );

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const typeOptions = () => {
    if (ready) {
      const arrayTypeOption = [] as any;
      Object.keys(TrainingsType).forEach((key) => {
        arrayTypeOption.push({
          value: TrainingsType[key],
          label: t(`trainings:trainingsTypeEnum.${TrainingsType[key]}`),
        });
      });
      return arrayTypeOption;
    }
    return [];
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            editClick={() => {
              history.push(
                `/administracja/szkolenia/grupy-szkoleniowe/${row.training.id}`
              );
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'training.name',
      sort: true,
      text: 'Nazwa szkolenia',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText
          customFilter={dataCustomFilterData}
          column={column}
          valueDefault={filterReq?.name || undefined}
        />
      ),

      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'groupsCount',
      text: 'Liczba grup',
      align: 'center',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'allRegistered',
      text: 'Łączna liczba osób',
      align: 'center',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'groupRegistrationsCount',
      text: 'Liczba osób niezapisanych',
      align: 'center',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'training.type',
      text: 'Typ szkolenia',
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="right"
          />
        );
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelect
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
          options={typeOptions()}
          valueDefault={filterReq?.type || undefined}
        />
      ),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellTypeTrainings data={row?.training.type} justify="toRight" />
        );
      },
    },
  ];

  return (
    <>
      <ContentBox id="managerUserPlansTrainingsGroupEmployeeTable" noPadding>
        <Box
          id="trainingsGroupBox"
          name="GRUPY SZKOLENIOWE"
          svgIcon="trainingsGroup"
          noPadding
        >
          <>
            <LoaderTable active={loaderTable} noRadiusTop>
              <>
                <TablePagination
                  rowInPage={rowsPerPage}
                  page={page}
                  changeRowInPage={setRowsPerPage}
                  data={data}
                  changePage={setPage}
                >
                  <ShowTitleTable
                    id="titleTableEmployee"
                    label="Lista szkoleń, na które została wyrażona zgodna na szkolenie:"
                  />
                </TablePagination>
                <BootstrapTable
                  bootstrap4
                  hover
                  bordered={false}
                  keyField="id"
                  data={data?.content || []}
                  columns={columns}
                  noDataIndication={t('managerPlans.noDataTableEmployee')}
                  remote={{
                    filter: true,
                    sort: true,
                  }}
                  onTableChange={onTableChange}
                  filter={filterFactory()}
                  defaultSorted={[
                    {
                      dataField: JSON.parse(orderColumn)?.name
                        ? JSON.parse(orderColumn)?.name.toLowerCase()
                        : 'training.name',
                      order:
                        JSON.parse(orderColumn)?.order === 'desc'
                          ? 'desc'
                          : 'asc',
                    },
                  ]}
                />
                <TablePagination
                  rowInPage={rowsPerPage}
                  page={page}
                  changeRowInPage={setRowsPerPage}
                  data={data}
                  changePage={setPage}
                />
              </>
            </LoaderTable>
            <div id="sendEmailNotifications">
              <div id="sendBox">
                <div id="sendBoxIcon">
                  <RenderSvg nameSvg="sendMailNotyfication" />
                </div>
                <div id="sendBoxInfo">
                  Wyślij powiadomienie do uczestników szkoleń o konieczności
                  zapisów do grup szkoleniowych:
                </div>
                <div id="sendBoxAction">
                  <Button
                    className="green"
                    onClick={() => handleClickSentNotification()}
                  >
                    Wyślij mail
                  </Button>
                </div>
              </div>
              <div id="lastSendEmailNotyfication">
                <div id="lastSendEmailNotyficationLabel">
                  Data wysłania powiadomienia o zapisach:
                </div>
                <div id="lastSendEmailNotyficationDataBox">
                  <div id="lastSendEmailNotyficationIcon">
                    <RenderSvg nameSvg="frameRegistrationValidationDate" />
                  </div>
                  <div id="lastSendEmailNotyficationData">
                    <span>
                      {dataSendNotification?.notificationDate
                        ? convertStringDatetoShortDate(
                            dataSendNotification?.notificationDate
                          )
                        : '---'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Box>
      </ContentBox>
    </>
  );
};

export default TableTrainingsBlock;
