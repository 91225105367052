import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ModalYesNo,
} from 'components';
import { UserPlansTrainingsRepository } from 'api/repository';
import { Button } from 'react-bootstrap';
import LimitBlock from './LimitBlock';
import TableTrainingsBlock from './TableTrainingsBlock';

import './editPlansTrainings.scss';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [dataApprove] = UserPlansTrainingsRepository.useGetApprovePlan();

  const [approve, setApprove] = useState<boolean>(false);

  useEffect(() => {
    if (dataApprove !== undefined && dataApprove !== null) {
      setApprove(dataApprove);
    }
  }, [dataApprove]);

  const [updateLimitData, setUpdateLimitData] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);

  const history = useHistory();

  const approveTrainings = UserPlansTrainingsRepository.useApprovalSelectedTrainings(
    async () => {
      setShowModalYesNo(false);
      setApprove(true);
    }
  );

  const handleApproveTrainings = () => {
    setShowModalYesNo(true);
  };

  const [data] = UserPlansTrainingsRepository.useGetActivePlansTrainings();

  const [
    getLimitsData,
    limitsData,
  ] = UserPlansTrainingsRepository.useGetLimitsActivePlansTrainings();

  useEffect(() => {
    getLimitsData();
  }, [getLimitsData, updateLimitData]);

  return (
    <>
      <CustomContainer>
        <LoaderTable radiusTop allowGrow>
          <>
            <Box
              id="userPlansTrainingsEnrollmentBox"
              name={
                approve === false
                  ? t('userPlans.enrollmentForTraining')
                  : t('userPlans.enrollmentForTrainingApprove')
              }
              svgIcon="enrollmentForTrainings"
              noPadding
            >
              <>
                <ContentBox
                  id="userPlansTrainingsEnrollmentBasicInformation"
                  bottomBorder
                >
                  <>
                    <div className="planNameBox">
                      <div className="titleName">Wybrany plan szkoleń:</div>
                      <div className="name">{data?.name}</div>
                    </div>
                    <div className="dateBox">
                      <div id="frameDate" className="dateContent">
                        <div id="frameDateTitle" className="title">
                          {t('showPlans.frameDate')}
                        </div>
                        <div id="frameDateDetail" className="content">
                          <div id="frameDatesIcon" className="icon">
                            <RenderSvg nameSvg="frameDate" />
                          </div>
                          <div id="frameDateBoxDate" className="dateDetail">
                            <div id="frameDateStart">
                              {t('showPlans.from')}
                              <span className="start">{data?.startDate}</span>
                            </div>
                            <div id="frameDateEnd">
                              {t('showPlans.to')}
                              <span className="end">{data?.endDate}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="frameRegistrationDate" className="dateContent">
                        <div id="frameRegistrationDateTitle" className="title">
                          {t('showPlans.frameRegisterDate')}
                        </div>
                        <div
                          id="frameRegistrationDateDetail"
                          className="content"
                        >
                          <div id="frameRegistrationDateIcon" className="icon">
                            <RenderSvg nameSvg="frameRegistrationDate" />
                          </div>
                          <div
                            id="frameRegistrationDateBoxDate"
                            className="dateDetail"
                          >
                            <div id="frameRegistrationDateStart">
                              {t('showPlans.from')}
                              <span className="start">
                                {data?.registrationStartDate}
                              </span>
                            </div>
                            <div id="frameRegistrationDateEnd">
                              {t('showPlans.to')}
                              <span className="end">
                                {data?.registrationEndDate}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="validationDate" className="dateContent">
                        <div id="validationDateTitle" className="title">
                          {t('showPlans.validDate')}
                        </div>
                        <div id="validationDateDetail" className="content">
                          <div id="validationDateIcon" className="icon">
                            <RenderSvg nameSvg="dateValidationDate" />
                          </div>
                          <div
                            id="validationDateBoxDate"
                            className="dateDetail"
                          >
                            <div id="validationDateEnd">
                              <span className="end">
                                {data?.verificationDate}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </ContentBox>
                <LimitBlock maxLimits={data?.limits} limitsData={limitsData} />
                <TableTrainingsBlock
                  maxLimits={data?.limits}
                  limitsData={limitsData}
                  setUpdateLimits={setUpdateLimitData}
                  approve={approve}
                />
                {approve === false ? (
                  <ContentBox id="approveTrainingsPlans">
                    <>
                      <Button
                        className="green"
                        onClick={() => handleApproveTrainings()}
                      >
                        {t('userPlans.approveTrainings')}
                      </Button>
                      {showModalYesNo ? (
                        <ModalYesNo
                          changeModalShow={setShowModalYesNo}
                          yesAction={() => approveTrainings()}
                          noAction={() => setShowModalYesNo(false)}
                          title={t('userPlans.wantApproveTraining')}
                          additionalText={t(
                            'userPlans.approveTrainingAdditionalText'
                          )}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  </ContentBox>
                ) : (
                  ''
                )}
              </>
            </Box>
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default Trainigns;
