/* eslint-disable react-hooks/rules-of-hooks */
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from 'components';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  ExportGenerateReportsEmployeeAppraisalAdditionalParamGetPutDelete,
  ExportGenerateReportsEmployeeAppraisalPost,
} from 'api/types/EmployeeAppraisal';
import FormGenerateReportsEmployeeAppraisal from '../FormGenerateReportsEmployeeAppraisal';
// import CatalogsPlans from './CatalogsPlans';
// import PlansTrainingsShow from './PlansTrainingsShow';

interface Props {
  dataId: number;
  changeModalGenerateReports: Dispatch<SetStateAction<boolean>>;
  // onChange: () => void;
  data: any;
}

const modalGenerateReportsEmployeeApraisal = (props: Props): ReactElement => {
  const { dataId, data, changeModalGenerateReports } = props;
  const { t } = useTranslation('plansTrainings');

  const [pdfBlob, setPdfBlob] = useState<any | undefined>(undefined);
  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const generateReports = EmployeeAppraisalRepository.usePostGenerateReportEmployeeAppraisalExport(
    async (blobExcel) => {
      const fileGenerateReports = new Blob([blobExcel], {
        type: 'application/excel',
      });
      const fileURL = URL.createObjectURL(fileGenerateReports);
      setPdfBlob(fileURL);
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleGenerate = (
    dataForm: ExportGenerateReportsEmployeeAppraisalPost
  ) => {
    setSubmitForm(true);
    const additionalParam: ExportGenerateReportsEmployeeAppraisalAdditionalParamGetPutDelete = {
      evolutionId: dataId,
    };
    generateReports(dataForm, additionalParam);
  };

  return (
    <>
      <Modal
        open
        changeModalShow={changeModalGenerateReports}
        widthBox="high"
        maxLevel="lvl3"
      >
        <Box
          id="plansIDPBox"
          name={t('generateReports.nameBoxGenerateReports')}
          svgIcon="addEdit"
          noContentStyle
        >
          <FormGenerateReportsEmployeeAppraisal
            dataId={dataId}
            /* cancel={() => {
              changeModalEditPrint(false);
            }} */
            submitForm={submitForm}
            link={pdfBlob}
            onGenerate={handleGenerate}
            data={data}
          />
        </Box>
      </Modal>
    </>
  );
};

export default modalGenerateReportsEmployeeApraisal;
