import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import PlansEmployeeAppraisal from './PlansEmployeeAppraisal';
// import CatalogsPlans from './CatalogsPlans';
// import PlansTrainingsShow from './PlansTrainingsShow';

interface Props {
  changeModalEmployeeAppraisal: Dispatch<SetStateAction<boolean>>;
}

const modalIDP = (props: Props): ReactElement => {
  const { changeModalEmployeeAppraisal } = props;

  return (
    <>
      <Modal
        open
        changeModalShow={changeModalEmployeeAppraisal}
        widthBox="high"
        maxLevel="lvl2"
      >
        <PlansEmployeeAppraisal />
      </Modal>
    </>
  );
};

export default modalIDP;
