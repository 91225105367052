/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import './catalogsPlansItemIDP.scss';
import ModalGeneratePDFIDP from '../../../ModalGenerateReportsIDP/ModalGenerateReportsIDP';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  data: any;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data } = props;
  const { t } = useTranslation('plansTrainings');
  const actualDate = new Date();
  const [modalGeneratePDFIDP, setModalGeneratePDFIDP] = useState<boolean>(
    false
  );

  return (
    <>
      <div
        className={`CatalogsPlansItemIDP ${
          actualDate >= new Date(data.startDate) &&
          actualDate <= new Date(data.endDate)
            ? ''
            : 'noActive'
        }`}
        id={`${id}-${data.id}`}
      >
        <div className="accent" />
        <div className="dateOfDataEntry">
          <div className="dateOfDataEntryLabel">Daty wprowadzenia danych:</div>
          <div className="dateOfDataEntryDates">
            <div className="startDateOfDataEntryDatesBox">
              Od: <span className="startDate">{data.annualInterviewStart}</span>
            </div>
            <div className="endDateOfDataEntryDatesBox">
              Do: <span className="endDate">{data.annualInterviewEnd}</span>
            </div>
          </div>
        </div>
        <div className="dateOfSemiAnnualVerification">
          <div className="dateOfSemiAnnualVerificationLabel">
            Daty weryfikacji półrocznej:
          </div>
          <div className="dateOfSemiAnnualVerificationDates">
            <div className="startDateOfSemiAnnualVerificationDatesBox">
              Od:{' '}
              <span className="startDate">{data.sixMonthInterviewStart}</span>
            </div>
            <div className="endDateOfSemiAnnualVerificationDatesBox">
              Do: <span className="endDate">{data.sixMonthInterviewEnd}</span>
            </div>
          </div>
        </div>
        <div className="annualVerificationDate">
          <div className="annualVerificationDateLabel">
            Daty weryfikacji rocznej:
          </div>
          <div className="annualVerificationDateDates">
            <div className="startAnnualVerificationDateBox">
              Od:{' '}
              <span className="startDate">{data.nextAnnualInterviewStart}</span>
            </div>
            <div className="endAnnualVerificationDateBox">
              Do: <span className="endDate">{data.nextAnnualInterviewEnd}</span>
            </div>
          </div>
        </div>
        <div className="actionIDP">
          <Button
            className="green"
            onClick={() => setModalGeneratePDFIDP(true)}
          >
            {t('planBox.generateReports')}
          </Button>
        </div>
      </div>
      {modalGeneratePDFIDP ? (
        <ModalGeneratePDFIDP
          changeModalGenerateReports={setModalGeneratePDFIDP}
          dataId={data.id}
          data={data}
          // onChange={setModalGeneratePDF(true)}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ContentBox;
