import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentBox,
  CustomFilterAddress,
  CustomFilterData,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  ShowTitleTable,
  TableCellLastFirstName,
  TableCellSwitchSingle,
  TablePagination,
  TableRecordAction,
  TableCellInput,
  CustomSubmit,
  ExitFromForm,
  CustomFilterLastFirstName,
} from 'components';
import { TrainingsGroupRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { useHistory, useParams } from 'react-router-dom';

const TableTrainingsBlock = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');
  const history = useHistory();

  const { id, idRecord } = useParams<{
    id: string;
    idRecord: string;
  }>();

  const [valueEmployee, setValueEmployee] = useState<any>({});
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [changeForm, setChangeForm] = useState<boolean>(false);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    firstName: 'firstName',
    lastName: 'lastName',
    locations: 'location',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      lastName: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    lastName: 'LAST_NAME',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useGetTrainingsGroupGetEmployee();

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [modalSelect, setModalSelect] = useState<boolean>(false);

  const handleModalSelect = () => {
    setModalSelect(true);
  };

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  function handleChangePresence(idEmployee: number, rowIndex: number) {
    const tempValueEmployee = valueEmployee;
    if (tempValueEmployee[idEmployee] === undefined) {
      tempValueEmployee[idEmployee] = {
        score:
          data?.content[rowIndex].score !== null
            ? data?.content[rowIndex].score
            : '',
        presence: !data?.content[rowIndex].presence,
      };
    } else {
      tempValueEmployee[idEmployee].presence = !tempValueEmployee[idEmployee]
        .presence;
    }
    setChangeForm(true);
    setValueEmployee(tempValueEmployee);
  }

  function handleChangeScore(
    idEmployee: number,
    valueScore: string,
    rowIndex: number
  ) {
    const tempValueEmployee = valueEmployee;
    if (tempValueEmployee[idEmployee] === undefined) {
      tempValueEmployee[idEmployee] = {
        score: valueScore,
        presence: data?.content[rowIndex].presence,
      };
    } else {
      tempValueEmployee[idEmployee].score = valueScore;
    }
    setChangeForm(true);
    setValueEmployee(tempValueEmployee);
  }

  const postDataSave = TrainingsGroupRepository.usePutEmployeePresenceAndScore(
    Number(idRecord),
    async () => {
      setSubmitForm(false);
      setLoaderTable(false);
      setChangeForm(false);
    },
    async () => {
      setSubmitForm(false);
      setLoaderTable(false);
    }
  );

  const handleSave = () => {
    setSubmitForm(true);
    setLoaderTable(true);
    postDataSave({ employeeRequest: valueEmployee });
  };

  const postDataSaveAndExit = TrainingsGroupRepository.usePutEmployeePresenceAndScore(
    Number(idRecord),
    async () => {
      setSubmitForm(false);
      setLoaderTable(false);
      setChangeForm(false);
      history.push(`/administracja/szkolenia/grupy-szkoleniowe/${id}`);
    },
    async () => {
      setSubmitForm(false);
      setLoaderTable(false);
    }
  );

  const handleSaveAndExit = () => {
    setSubmitForm(true);
    setLoaderTable(true);
    postDataSaveAndExit({ employeeRequest: valueEmployee });
  };

  const cancel = () => {
    history.push(`/administracja/szkolenia/grupy-szkoleniowe/${id}`);
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      id: Number(idRecord),
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            deleteClick={() => {}}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'lastName',
      text: 'Nazwisko i imię',
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterLastFirstName
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellLastFirstName
            id={row?.id}
            last={row?.lastName}
            first={row?.firstName}
          />
        );
      },
    },
    {
      dataField: 'locations',
      text: 'Nazwa lokalizacji',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
    },
    {
      dataField: '',
      text: 'Wynik',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellInput
            id="scoreEmployee"
            dataId={row.id}
            justify="toRight"
            handleChange={(rId: number, rVal: string) => {
              handleChangeScore(rId, rVal, rowIndex);
            }}
            defaultValue={row.score}
          />
        );
      },
    },

    {
      dataField: '',
      text: 'Obecność',
      headerStyle: () => {
        return { width: '80px' };
      },
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="right"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellSwitchSingle
            id="presenceEmployee"
            dataId={row.id}
            justify="toRight"
            handleChangeSelect={() => {
              handleChangePresence(row.id, rowIndex);
            }}
            defaultValueTrue={row.presence}
          />
        );
      },
    },
  ];

  return (
    <>
      <ContentBox id="managerUserPlansTrainingsEmployee" noPadding bottomBorder>
        <>
          <LoaderTable active={loaderTable} noRadiusTop>
            <>
              <ExitFromForm changeForm={changeForm} />

              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              >
                <ShowTitleTable
                  id="titleTableEmployee"
                  label="Lista osób, które ukończyły szkolenie"
                />
              </TablePagination>
              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={data?.content || []}
                columns={columns}
                noDataIndication={t('managerPlans.noDataTableEmployee')}
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              />
            </>
          </LoaderTable>
        </>
      </ContentBox>
      <CustomSubmit
        save={handleSave}
        saveAndExit={handleSaveAndExit}
        cancel={cancel}
        submitForm={submitForm}
      />
    </>
  );
};

export default TableTrainingsBlock;
