/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const LogoutComp = (): ReactElement => {
  const { keycloak } = useKeycloak();

  return (
    <div
      className="topMenuLogout"
      role="button"
      onClick={() => {
        keycloak.logout();
      }}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <div className="topMenuLogoutBlock">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.7733 16.7867L18.2267 13.3333H5.33333V10.6667H18.2267L14.7733 7.21333L16.6667 5.33333L23.3333 12L16.6667 18.6667L14.7733 16.7867ZM21.3333 0C22.0406 0 22.7189 0.280951 23.219 0.781048C23.719 1.28115 24 1.95942 24 2.66667V8.89333L21.3333 6.22667V2.66667H2.66667V21.3333H21.3333V17.7733L24 15.1067V21.3333C24 22.0406 23.719 22.7189 23.219 23.219C22.7189 23.719 22.0406 24 21.3333 24H2.66667C1.95942 24 1.28115 23.719 0.781048 23.219C0.280951 22.7189 0 22.0406 0 21.3333V2.66667C0 1.18667 1.18667 0 2.66667 0H21.3333Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default LogoutComp;
