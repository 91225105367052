import React, { ReactElement } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './customTooltip.scss';

interface Props {
  customTitle?: string;
  customString?: string;
  customList?: any;
  customHtml?: string;
  infoColor?: 'red' | 'green';
}

const CustomTooltip = (props: Props): ReactElement => {
  const {
    customTitle,
    customString,
    customList,
    customHtml,
    infoColor,
  } = props;

  const text = (
    <>
      <div className="labelCustomTooltip">{customTitle || 'OBJAŚNIENIE'}</div>
      {customString ? (
        <div className="textCustomTooltip">{customString}</div>
      ) : (
        ''
      )}
      {customHtml ? (
        <div
          className="textCustomTooltip"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={
            customHtml ? { __html: customHtml } : undefined
          }
        />
      ) : (
        ''
      )}
      {customList !== undefined ? (
        <div className="listCustomTooltip">
          {customList ? (
            <>
              {Object.keys(customList).map((k) => {
                return (
                  <>
                    <div className="listName">{k}:</div>
                    <div className="listValue">
                      {typeof customList[k] === 'string' ? customList[k] : ''}
                      {k === 'Przełożony' ? (
                        <>
                          {customList[k].lastName} {customList[k].firstName}
                        </>
                      ) : (
                        <>
                          {typeof customList[k] === 'object'
                            ? Object.keys(customList[k]).map((kk, ii) => {
                                return (
                                  <>
                                    {customList[k][kk]}
                                    {customList[k].length !== undefined &&
                                    customList[k].length - 1 !== ii
                                      ? ', '
                                      : ''}
                                  </>
                                );
                              })
                            : ''}
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
  return (
    <span className={`customTooltipBox  ${infoColor || ''}`}>
      <Tooltip overlayClassName="customTooltip" placement="left" overlay={text}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.558 0C12.5511 0.00669824 12.5375 0.00669824 12.517 0.00669824C5.61391 0.00669824 0 5.51935 0 12.298C0 15.0643 0.9618 17.7503 2.72169 19.9139L0.900408 24.0668C0.75034 24.4084 0.914051 24.8036 1.25511 24.9442C1.3779 24.9978 1.51432 25.0112 1.64393 24.9911L8.32196 23.839C9.65893 24.3146 11.0641 24.5557 12.4829 24.549C19.3861 24.549 25 19.0364 25 12.2578C25.0136 5.49925 19.4406 0.00669824 12.558 0ZM12.4898 23.2161C11.1732 23.2161 9.87039 22.9817 8.63574 22.5262C8.51977 22.4793 8.39699 22.4726 8.27421 22.4927L2.65348 23.4572L4.14051 20.0612C4.24283 19.8268 4.20191 19.5522 4.03137 19.3579C3.22646 18.4403 2.58526 17.3953 2.13506 16.2633C1.63028 14.9974 1.37108 13.651 1.37108 12.2913C1.37108 6.24946 6.37789 1.33965 12.5239 1.33965C18.6562 1.32625 23.6357 6.19587 23.6425 12.2176C23.6425 12.231 23.6425 12.2444 23.6425 12.2578C23.6425 18.3063 18.6357 23.2161 12.4898 23.2161Z"
            fill="#1D4370"
          />
          <path
            d="M11.624 4C11.216 4 11 4.24 11 4.624V5.992C11 6.424 11.216 6.616 11.624 6.616H12.5839C13.0159 6.616 13.2079 6.424 13.2079 5.992V4.624C13.2079 4.24 13.0159 4 12.5839 4H11.624ZM13.1119 20.656V8.968H11.096V20.656H13.1119Z"
            fill="#1D4370"
          />
        </svg>
      </Tooltip>
    </span>
  );
};

export default CustomTooltip;
