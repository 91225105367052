/* eslint-disable react/jsx-no-bind */
import React, { ReactElement, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TrainingsCompanyContactRepository } from 'api/repository';
import {
  Box,
  TableRecordAction,
  TableCellDefault,
  TableCellContact,
  TablePagination,
  LoaderTable,
} from 'components';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalContactAdd from './ModalContactAdd';
import ModalContactShow from './ModalContactShow';
import ModalContactEdit from './ModalContactEdit';

const TableAdress = (): ReactElement => {
  const { t } = useTranslation('trainingsCompany');
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [
    getData,
    data,
  ] = TrainingsCompanyContactRepository.useGetAllTrainingsCompanyContact({});

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleEditClick = (idClick: number) => {
    setModalId(idClick);
    setModalEdit(true);
  };

  const DeleteRecord = TrainingsCompanyContactRepository.useDeleteTrainingsCompanyContact(
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const HandleDeleteClick = (idClick: number) => {
    setModalId(idClick);
    DeleteRecord(idClick);
  };

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={handleShowClick}
            editClick={handleEditClick}
            archiveClick={HandleDeleteClick}
            rowNumber={rowIndex}
            page={1}
            rowsPerPage={1000}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('contact.name'),
    },
    {
      dataField: '',
      text: t('contact.contact'),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellContact data={row} />;
      },
    },
  ];

  return (
    <>
      <Box
        id="trainingCompanyBox"
        name={t('contact.CONTACT')}
        svgIcon="contact"
        noPadding
      >
        <LoaderTable active={loaderTable}>
          <>
            <TablePagination>
              <Button className="green" onClick={() => handleOpenAddForm()}>
                {t('contact.addContact')}
              </Button>
            </TablePagination>
            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={data?.content || []}
              columns={columns}
              noDataIndication={t('contact.noData')}
              remote={{
                filter: true,
                sort: true,
              }}
            />
          </>
        </LoaderTable>
      </Box>

      {modalShow ? (
        <ModalContactShow idRecord={modalId} changeModalShow={setModalShow} />
      ) : (
        ''
      )}
      {modalAdd ? (
        <ModalContactAdd
          changeModalAdd={setModalAdd}
          changeModalEdit={setModalEdit}
          changeModalId={setModalId}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
      {modalEdit ? (
        <ModalContactEdit
          idRecord={modalId}
          changeModalEdit={setModalEdit}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TableAdress;
