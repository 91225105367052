import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
import {
  Box,
  CustomFilterData,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  TablePagination,
  TableRecordAction,
  CustomContainer,
} from 'components';
import { CompetencesRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import ModalCompetencesAdd from './ModalCompetencesAdd';
import ModalCompetencesEdit from './ModalCompetencesEdit';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('competences');

  const getStorageTableData = () => {
    const data = window.sessionStorage.getItem('competencesBoxTable');
    if (data === null) {
      return null;
    }
    return JSON.parse(data);
  };

  const storageTable = getStorageTableData();

  const [page, setPage] = React.useState(
    storageTable === null ? 1 : storageTable.pageNumber + 1
  );
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search

  const mapSearch: { [key: string]: string } = {
    name: 'name',
  };

  const getFilterData = (): { [key: string]: string } => {
    if (storageTable === null) {
      return {};
    }
    let newSort = {} as { [key: string]: string };
    Object.keys(mapSearch).forEach((v) => {
      if (storageTable?.[mapSearch?.[v]]) {
        newSort = {
          ...newSort,
          [mapSearch?.[v]]: storageTable?.[mapSearch?.[v]],
        };
      }
    });
    return newSort;
  };
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>(
    getFilterData()
  );

  // End variable search

  // variable order
  const getOrderData = (): string => {
    if (storageTable === null) {
      return JSON.stringify({
        lastName: 'default',
        order: 'asc',
      });
    }

    let newOrder = {
      lastName: 'default',
      order: storageTable.ascending ? 'asc' : 'desc',
    } as { [key: string]: string };
    if (storageTable.sort) {
      newOrder = { ...newOrder, name: storageTable.sort };
    }
    return JSON.stringify(newOrder);
  };

  const [orderColumn, setOrderColumn] = useState<string>(getOrderData());
  const mapOrder: { [key: string]: string } = {
    name: 'default',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [getData, data] = CompetencesRepository.useGetAllCompetences();
  const deleteTrainingsProvider = CompetencesRepository.useDeleteCompetences();
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }
    window.sessionStorage.setItem(
      'competencesBoxTable',
      JSON.stringify(paramQuery)
    );
    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const handleEditClick = (idClick: number) => {
    setModalId(idClick);
    setModalEdit(true);
  };

  const DeleteRecord = CompetencesRepository.useDeleteCompetences(
    async (id) => {
      setUpdateDataTable(Math.random);
    }
  );

  const HandleDeleteClick = (idClick: number) => {
    setModalId(idClick);
    DeleteRecord(idClick);
  };

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            editClick={handleEditClick}
            archiveClick={HandleDeleteClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('competencesTable.name'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText
          customFilter={dataCustomFilterData}
          column={column}
          valueDefault={filterReq?.name || undefined}
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        id="competencesBox"
        name={t('competencesTable.nameTable')}
        svgIcon="competence"
        darkHeader
        noPadding
      >
        <LoaderTable active={loaderTable}>
          <>
            <TablePagination
              rowInPage={rowsPerPage}
              page={page}
              changeRowInPage={setRowsPerPage}
              data={data}
              changePage={setPage}
            >
              <Button className="green" onClick={() => handleOpenAddForm()}>
                {t('competencesTable.addCompetences')}
              </Button>
            </TablePagination>

            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={data?.content || []}
              columns={columns}
              noDataIndication={t('competencesTable.noDataTable')}
              remote={{
                filter: true,
                sort: true,
              }}
              onTableChange={onTableChange}
              filter={filterFactory()}
              defaultSorted={[
                {
                  dataField: JSON.parse(orderColumn)?.name
                    ? JSON.parse(orderColumn)?.name.toLowerCase()
                    : 'name',
                  order:
                    JSON.parse(orderColumn)?.order === 'desc' ? 'desc' : 'asc',
                },
              ]}
            />
            <TablePagination
              rowInPage={rowsPerPage}
              page={page}
              changeRowInPage={setRowsPerPage}
              data={data}
              changePage={setPage}
            />
          </>
        </LoaderTable>
      </Box>
      {modalAdd ? (
        <ModalCompetencesAdd
          changeModalAdd={setModalAdd}
          changeModalEdit={setModalEdit}
          changeModalId={setModalId}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
      {modalEdit ? (
        <ModalCompetencesEdit
          id={modalId}
          changeModalEdit={setModalEdit}
          changeUpdateTableData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Trainigns;
