import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CustomContainer, LoaderTable, Tabs } from 'components';
import { IDPRepository } from 'api/repository';

import IDPEmployeeDetail from './IDPEmployeeDetail';

import IDPEmployeeArea from './IDPEmployeeArea';
import IDPEmployeeLeaderProfile from './IDPEmployeeLeaderProfile';
import IDPFeedback from './IDPFeedback';

import './IDPDetail.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { idEmployee, action } = useParams<{
    idEmployee: string;
    action: string;
  }>();

  const [IDPEmployee, fetchData] = IDPRepository.useGetIDPEmployee({
    id: Number(idEmployee),
  });

  const nameBox = () => {
    let textName = 'EDYCJA IDP - ';
    if (IDPEmployee && IDPEmployee.interviewType === 'ANNUAL') {
      textName = 'EDYCJA IDP - WPROWADZANIE DANYCH';
    }
    if (IDPEmployee && IDPEmployee.interviewType === 'FIRST_ANNUAL') {
      textName = 'EDYCJA IDP - WPROWADZANIE DANYCH';
    }
    if (IDPEmployee && IDPEmployee.interviewType === 'SIX_MONTH') {
      textName = 'EDYCJA IDP - ROZMOWA PÓŁROCZNA';
    }
    if (IDPEmployee && IDPEmployee.interviewType === 'LAST_ANNUAL') {
      textName = 'EDYCJA IDP - ROZMOWA ROCZNA';
    }
    return textName;
  };

  return (
    <>
      <CustomContainer>
        <LoaderTable active={IDPEmployee === undefined}>
          <Box
            id="IDPEmployeeBox"
            name={IDPEmployee === null ? 'DODAWANIE IDP' : nameBox()}
            svgIcon="addEdit"
            noPadding
          >
            <>
              {IDPEmployee === null ? (
                <div id="IDPEmployeeContentBoxEmpty">
                  <IDPEmployeeDetail
                    IDPEmployee={IDPEmployee}
                    fetchData={fetchData}
                  />
                </div>
              ) : (
                <>
                  <Tabs
                    id="IDPEmployeeTabs"
                    items={[
                      {
                        id: 'dane-podstawowe',
                        label: 'Cele, komentarze, mobilność',
                        link: `/kierownik/IDP/pracownicy/${idEmployee}/dane-podstawowe`,
                        svgName: 'target',
                      },
                      {
                        id: 'obszary-rozwoju',
                        label: 'Obszary rozwoju',
                        link: `/kierownik/IDP/pracownicy/${idEmployee}/obszary-rozwoju`,
                        svgName: 'searchAndGraph',
                      },
                      /* {
                        id: 'profil-lidera',
                        label: 'Profil lidera',
                        link: `/kierownik/IDP/pracownicy/${idEmployee}/profil-lidera`,
                        svgName: 'employeeWithStar',
                      }, */
                      {
                        id: 'feedback-nt-wartosci',
                        label: 'Feedback nt wartości',
                        link: `/kierownik/IDP/pracownicy/${idEmployee}/feedback-nt-wartosci`,
                        svgName: 'feedback',
                        disabled:
                          IDPEmployee?.interviewType === 'LAST_ANNUAL'
                            ? undefined
                            : true,
                      },
                    ]}
                  />
                  <div id="IDPEmployeeContentBox">
                    {action === 'dane-podstawowe' ? (
                      <IDPEmployeeDetail IDPEmployee={IDPEmployee} />
                    ) : (
                      ''
                    )}
                    {action === 'obszary-rozwoju' ? (
                      <IDPEmployeeArea
                        IDPEmployee={IDPEmployee}
                        fetchData={fetchData}
                      />
                    ) : (
                      ''
                    )}
                    {/* action === 'profil-lidera' ? (
                      <IDPEmployeeLeaderProfile
                        IDPEmployee={IDPEmployee}
                        fetchData={fetchData}
                      />
                    ) : (
                      ''
                    ) */}
                    {action === 'feedback-nt-wartosci' ? (
                      <IDPFeedback IDPEmployee={IDPEmployee} />
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          </Box>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EditAcceptedTrainings;
