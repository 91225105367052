/* eslint-disable react-hooks/rules-of-hooks */
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from 'components';
import { PlansTrainingsRepository } from 'api/repository';
import {
  ExportGenerateReportsAdditionalParamGetPutDelete,
  ExportGenerateReportsPost,
} from 'api/types/PlansTrainings';
import FormGenerateReportsTrainings from '../Forms/FormGenerateReportsTrainings';

// import CatalogsPlans from './CatalogsPlans';
// import PlansTrainingsShow from './PlansTrainingsShow';

interface Props {
  dataId: number;
  changeModalGenerateReports: Dispatch<SetStateAction<boolean>>;
  // onChange: () => void;
  data: any;
}

const modalGenerateReports = (props: Props): ReactElement => {
  const { dataId, data, changeModalGenerateReports } = props;
  const { t } = useTranslation('plansTrainings');

  const [pdfBlob, setPdfBlob] = useState<any | undefined>(undefined);
  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const generateExcel = PlansTrainingsRepository.usePostGenerateReportsExport(
    async (blobExcel) => {
      const fileGenerateExcel = new Blob([blobExcel], {
        type: 'application/excel',
      });
      const fileURL = URL.createObjectURL(fileGenerateExcel);
      setPdfBlob(fileURL);
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleGenerate = (dataForm: ExportGenerateReportsPost) => {
    setSubmitForm(true);
    const additionalParam: ExportGenerateReportsAdditionalParamGetPutDelete = {
      id: dataId,
    };
    generateExcel(dataForm, additionalParam);
  };

  return (
    <>
      <Modal
        open
        changeModalShow={changeModalGenerateReports}
        widthBox="high"
        maxLevel="lvl3"
      >
        <Box
          id="plansTrainingsBox"
          name={t('generateReports.nameBoxGenerateReports')}
          svgIcon="planTrainings"
          noContentStyle
        >
          <FormGenerateReportsTrainings
            dataId={dataId}
            /* cancel={() => {
              changeModalEditPrint(false);
            }} */
            submitForm={submitForm}
            link={pdfBlob}
            onGenerate={handleGenerate}
            data={data}
          />
        </Box>
      </Modal>
    </>
  );
};

export default modalGenerateReports;
