import React from 'react';
import { RouteComponentProps, Route, useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from 'react-bootstrap';
import './authRoute.scss';

interface Props {
  Component: any;
  path: string;
  exact?: boolean;
  requiredRoles: string[];
}

const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRoles,
}: Props): JSX.Element => {
  const history = useHistory();

  const { keycloak } = useKeycloak();
  const isAuthorized = (roles) => {
    if (keycloak && roles) {
      return roles.some((role) => {
        const realm = keycloak.hasRealmRole(role);
        const resource = keycloak.hasResourceRole(role);
        return realm || resource;
      });
    }
    return false;
  };

  const isAuthenticated = (props: RouteComponentProps) => {
    if (keycloak.authenticated) {
      if (isAuthorized(requiredRoles)) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...props} />;
      }
      return (
        <div id="noPermission">
          <div className="noPermissionText">Nie posiadasz uprawnień</div>
          <div className="noPermissionButton">
            <Button
              className="green"
              onClick={() => history.push('/panel/tablica')}
            >
              Powrót na stronę główną
            </Button>
          </div>
        </div>
      );
    }
    keycloak?.login();
    return null;
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => isAuthenticated(props)}
    />
  );
};

export default AuthRoute;
