import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ContentBox,
  CustomFilterAddress,
  CustomFilterContact,
  CustomFilterData,
  CustomFilterLastFirstName,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  Modal,
  ShowTitleTable,
  TableCellAddress,
  TableCellContact,
  TableCellDeleteRow,
  TableCellImport,
  TableCellLastFirstName,
  TableCellListToCell,
  TableCellLoyaltyStatus,
  TableCellTypeTrainings,
  TableCellYesNo,
  TablePagination,
  TableRecordAction,
} from 'components';
import { TrainingsGroupRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { useHistory, useParams } from 'react-router-dom';

interface Props {
  setUpadateTableParent: Dispatch<SetStateAction<number>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const { setUpadateTableParent, setShowModal } = props;
  const history = useHistory();

  const { id, idRecord, action } = useParams<{
    id: string;
    idRecord: string;
    action: string;
  }>();

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    firstName: 'firstName',
    lastName: 'lastName',
    locations: 'location',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      lastName: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    lastName: 'lastName',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useGetTrainingsGroupGetAvailableEmployee();

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      id: Number(idRecord),
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const PostRecord = TrainingsGroupRepository.useRegisterEmployeeToGroup(
    async () => {
      setLoaderTable(false);
      setUpdateDataTable(Math.random);
      setUpadateTableParent(Math.random);
    },
    async () => {
      setLoaderTable(false);
    }
  );

  const handleRegisterEmployee = (idClick: number) => {
    PostRecord(Number(idRecord), idClick);
    setLoaderTable(true);
  };

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            importClick={() => {
              handleRegisterEmployee(row.id);
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'lastName',
      text: 'Nazwisko i imię',
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterLastFirstName
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellLastFirstName
            id={row?.id}
            last={row?.lastName}
            first={row?.firstName}
          />
        );
      },
    },
    {
      dataField: 'locations',
      text: 'Nazwa lokalizacji',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
    },
    {
      dataField: '',
      text: 'Importuj',
      headerStyle: () => {
        return { width: '80px' };
      },
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="right"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <>
            <TableCellImport
              id={row.id}
              justify="toRight"
              handleImport={() => {
                handleRegisterEmployee(row.id);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Modal open widthBox="high" changeModalShow={setShowModal}>
      <Box
        id="modalAddRegisterEmployee"
        name="IMPORT OSÓB NIEZAPISANYCH"
        svgIcon="importAction"
        noPadding
      >
        <ContentBox id="managerUserPlansTrainingsEmployee" noPadding>
          <>
            <LoaderTable active={loaderTable} noRadiusTop>
              <>
                <TablePagination
                  rowInPage={rowsPerPage}
                  page={page}
                  changeRowInPage={setRowsPerPage}
                  data={data}
                  changePage={setPage}
                >
                  <>Lista osób niezapisanych:</>
                </TablePagination>
                <BootstrapTable
                  bootstrap4
                  hover
                  bordered={false}
                  keyField="id"
                  data={data?.content || []}
                  columns={columns}
                  noDataIndication={t('managerPlans.noDataTableEmployee')}
                  remote={{
                    filter: true,
                    sort: true,
                  }}
                  onTableChange={onTableChange}
                  filter={filterFactory()}
                  defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                />
                <TablePagination
                  rowInPage={rowsPerPage}
                  page={page}
                  changeRowInPage={setRowsPerPage}
                  data={data}
                  changePage={setPage}
                />
              </>
            </LoaderTable>
          </>
        </ContentBox>
      </Box>
    </Modal>
  );
};

export default TableTrainingsBlock;
